<template>
    <div class="full-page">
        <div class="window loading-screen" v-if="!callReturned">
            <span class="top-text loading-text">Plan Loading....</span>
            <div class="lds-dual-ring"></div>
        </div>
        <div class="window" v-else-if="gotLoad404">
            <span class="title" style="font-size: 2em; margin-bottom: 1em;">Plan not found</span>
            <span class="subtitle" style="text-align: center">Couldn't find a plan with code {{ planKey }} in database. If you're sure that code is correct, Carter can help get everything sorted out!</span>
        </div>
        <div class="window" v-else-if="gotLoad500">
            <span class="title" style="font-size: 2em; margin-bottom: 1em;">Server Error</span>
            <span class="subtitle" style="text-align: center;">The server encountered an error trying to retrieve that plan. Try refreshing the page- if that doesn't work, please tell Carter about the error & include the plan code in your message! <a href="mailto:carter@dentalmenu.com" style="color:var(--color-bright-blue)">carter@dentalmenu.com</a></span>
         </div>
        <div class="window" v-else>
            <span class="top-text">Submit Plan</span>
            <span class="subtitle">for {{ practiceName }}</span>
            <div class="two-column-row">
                <span class="attribute-name">Monthly Fee:</span>
                <input type="text" v-model="monthlyFeeString" class="input-class hover-grow" @input="ValidateMonthlyFee()">
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Velox ID:</span>
                <input type="text" v-model="extraData.veloxID" class="input-class hover-grow" @input="ValidateVeloxID()">
            </div>
            <div class="two-column-row">
                <span class="attribute-name">PMS Insurance ID:</span>
                <input type="text" v-model="extraData.pmsInsuranceID" class="input-class hover-grow" @input="ValidatePMS()">
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Payout Method:</span>
                <select v-model="extraData.payoutMethod" class="input-class hover-grow" style="cursor: pointer">
                    <option disabled value="">Select Payout Method</option>
                    <option :value="'stripe_default'">Automated Daily Payouts</option>
                    <option :value="'manual_claims'">Manual Payouts</option>
                </select>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Enable Claims?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="ToggleClaims()">
                        <div class="checkbox-fill" v-if="extraData.enableClaims">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Enable Cash Payments?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="ToggleCash()">
                        <div class="checkbox-fill" v-if="extraData.enableCash">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Benefits available now?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="ToggleReadyNow()">
                        <div class="checkbox-fill" v-if="extraData.readyNow">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Is Pediatric Practice?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="TogglePediatric()">
                        <div class="checkbox-fill" v-if="extraData.isPediatric">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Supress Rewards?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="ToggleSuppressRewards()">
                        <div class="checkbox-fill" v-if="extraData.suppressRewards">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Waive Enrollment Fee for Annual Payments?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="ToggleWaiveForAnnual()">
                        <div class="checkbox-fill" v-if="extraData.waiveForAnnual">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-row">
                <span class="attribute-name">Enable Patient Portal?</span>
                <div class="checkbox-container input-class no-hover-border">
                    <div class="checkbox grow-medium" @click="TogglePortal()">
                        <div class="checkbox-fill" v-if="extraData.patientPortal">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
                <button class="submit-btn hover-grow" @click="HandleSubmitClick()" v-if="showSubmitBtn">
                    Submit Plan
                </button>
                <div class="submit submit-error" v-if="gotSubmit500">
                    There was an error while submitting this plan. No changes were made to the database. If this issue persists, get in touch with Carter!
                </div>
                <div class="submit submit-error" v-if="gotSubmit404">
                    Unable to locate a plan with this key. If you see this error, get in touch with Carter!
                </div>
                <div class="submit submit-error" v-if="gotSubmit400">
                    There's bad data somewhere in this practice's plans. Here's the error message returned by the server: <br/><br/>
                    {{ errorMessage }}<br/><br/>
                    If the error mentions the "slug" parameter, the landing page url the practice has chosen is already being used by another practice
                </div>
                <div class="submit submit-success" v-if="gotSubmit202">
                    Plan submitted successfully!
                </div>
                <button class="submit-btn hover-grow" @click="CreateLandingPage()" v-if="showCreateBtn">
                    {{ landingPageBtnText }}
                </button>
                <div class="submit submit-success" v-if="gotLanding201">
                    Landing Page Created Successfully!
                </div>
                <div class="submit submit-error" v-if="gotLanding400">
                    There's bad data somewhere in this practice's plans. Here's the error message returned by the server: <br/><br/>
                    {{ errorMessage }}<br/><br/>
                    If the error mentions the "slug" parameter, the landing page url the practice has chosen is already being used by another practice.
                </div>
                <div class="submit submit-error" v-if="gotLanding404">
                    Unable to locate a plan with this key to create a page for. If you see this error, get in touch with Carter!
                </div>
                <div class="submit submit-error" v-if="gotLanding500">
                    There was an error while submitting this plan. It's not likely, but a landing page may have been created. If this issue persists, get in touch with Carter!
                </div>
                <div class="submit submit-error" v-if="noLandingPageSpecified">
                    No landing page address has been specified, so the landing page can't be created yet & the practice can't be submitted. Please assign a landing page address on the contact form page of the onboarding flow here:
                    <a :href="planLink" target="_blank" style="margin-top: 0.08em; color: var(--color-dark-blue);">{{ planLink }}</a>
                </div>
        </div>
        <div class="modal-overlay" v-if="showWarn" @click.self="RemoveWarn">
            <WarnModal @click.stop @exitModal="RemoveWarn" @YesSubmit="SubmitPlan()">

            </WarnModal>
        </div>
    </div>
</template>

<script>
import { InputToInteger, GetPlanFromDB, UpdatePlanDataInDB, SubmitPlanToDB, CreateLandingPageUtil, CalculateComputedPlanPrice } from '../Onboarding/utils/functions';
import { mapActions } from 'vuex';
import WarnModal from './SubmitWarnModal.vue'
let dev = true;
let url = dev ? "http://127.0.0.1:8000/" : "https://app.dentmenu.com/v2/"
export default {
    components: {
        WarnModal
    },
    props: {
        planKey: String,
    },
    data() {
        return {
            practiceName: "",
            extraData: {
                monthlyFee: 4,
                veloxID: 0,
                pmsInsuranceID: 0,
                payoutMethod: "stripe_default",
                enableClaims: false,
                enableCash: false,
                readyNow: false,
                isPediatric: false,
                suppressRewards: false,
                waiveForAnnual: false,
                patientPortal: false,
            },            
            callReturned: false,
            gotLoad404: false,
            gotLoad500: false,
            gotSubmit404: false,
            gotSubmit500: false,
            gotSubmit202: false,
            gotSubmit400: false,
            gotLanding201: false,
            gotLanding400: false,
            gotLanding404: false,
            gotLanding500: false,
            noLandingPageSpecified: false,
            errorMessage: "",
            monthlyFeeString: "",
            landingPageBtnText: "Create Landing Page",
            showSubmitBtn: true,
            showCreateBtn: true,
            localPlan: null,
            planID: 0,
            showWarn: false,
            isSubmitted: false,
        }
    },
    computed: {
        monthlyFeeStore() {
            return 2;
        },
        planLink() {
            return "https://dentalmenuonboarding.com/build/" + this.planKey;
        },
        store() {
            return this.$store.state;
        }
    },
    methods: {
        ...mapActions(['setEverything', 'updatePlan', 'setDMFee']),
        ValidateMonthlyFee() {
            this.monthlyFeeString = this.monthlyFeeString.replace(/[^0-9.]/g, '');
            let num = parseFloat(this.monthlyFeeString);
            this.extraData.monthlyFee = num;
            this.setDMFee(num);
        },
        ValidateVeloxID() {
            this.extraData.veloxID = InputToInteger(this.extraData.veloxID);
        },
        ValidatePMS() {
            this.extraData.pmsInsuranceID = InputToInteger(this.extraData.pmsInsuranceID);
        },
        HandleSubmitClick() {
            if(this.isSubmitted) {
                console.log("1");
                this.DisplayWarn();
                return;
            }
            this.SubmitPlan();


        },
        async SubmitPlan() {
            console.log("Entering SubmitPlan");
            this.gotLoad404 = false;
            this.gotLoad500 = false;
            this.gotSubmit404 = false;
            this.gotSubmit500 = false;
            this.gotSubmit202 = false;
            this.gotSubmit400 = false;
            let response = "";

            if(this.localPlan.landingPage === "") {
                this.noLandingPageSpecified = true;
                this.showCreateBtn = false;
                this.showSubmitBtn = false;
                return;
            }

            if(this.localPlan.dentalMenuFee !== this.extraData.monthlyFee || this.localPlan.isPediatric !== this.extraData.isPediatric) {
                this.localPlan.dentalMenuFee = this.extraData.monthlyFee;
                this.localPlan.isPediatric = this.extraData.isPediatric;
                await UpdatePlanDataInDB(this.planID, this.localPlan);
                console.log("Plan updated in DB from submit");
            }
            //console.log("Blob = ", this.extraData);
            try {
                console.log("Submitting plan to DB");
                response = await SubmitPlanToDB(this.planKey, this.extraData);
                //console.log("Response = ", response)
            }
            catch(error) {
                if(error.response.status === 500) {
                    this.gotSubmit500 = true;
                    //console.log("500 received")
                    return;
                }
                else if(error.response.status === 404) {
                    this.gotSubmit404 = true;
                    return;
                }
                else if(error.response.status === 400) {
                    this.gotSubmit400 = true;
                    this.errorMessage = error.response.data;
                    return;
                }
            }
            if(response.status === 202) {
                this.gotSubmit202 = true;
            }
            this.showSubmitBtn = false;
        },
        async CreateLandingPage() {
            this.landingPageBtnText = "Creating..."
            if(this.localPlan.landingPage === "") {
                this.noLandingPageSpecified = true;
                this.showCreateBtn = false;
                this.showSubmitBtn = false;
                return;
            }
            if(this.localPlan.includeDMFee) {
                let plans = this.localPlan.plans;
                plans.forEach((plan) => {
                    if(plan.monthlyPriceAltered) {
                        return;
                    }
                    plan.price = CalculateComputedPlanPrice(this.$store, plan);
                    if(!plan.monthlyPriceAltered && !plan.annualPriceAltered) {
                        plan.annualPrice = plan.price * 12;
                    }
                    this.updatePlan(plan);
                })
            }
            this.localPlan = this.$store.state;
            this.localPlan.dentalMenuFee = this.extraData.monthlyFee;
            let result = UpdatePlanDataInDB(this.planID, this.localPlan);
            //console.log("Creating landing page now");
            let response = "";
            //console.log("Blob = ", this.extraData);
            try {
                response = await CreateLandingPageUtil(this.planKey, this.extraData);
            }
            catch(error) {
                return;
            }
            if(response.status === 201) {
                this.gotLanding201 = true;
            }
            else if(response.status === 400) {
                this.gotLanding400 = true;
            }
            else if(response.status === 404) {
                this.gotLanding404 = true;
            }
            else if(response.status === 500) {
                this.gotLanding500 = true;
            }
            this.showCreateBtn = false;
        },
        ToggleClaims() {
            this.extraData.enableClaims = !this.extraData.enableClaims;
        },
        ToggleCash() {
            this.extraData.enableCash = !this.extraData.enableCash;
        },
        ToggleReadyNow() {
            this.extraData.readyNow = !this.extraData.readyNow;
        },
        TogglePediatric() {
            this.extraData.isPediatric = !this.extraData.isPediatric;
        },
        ToggleSuppressRewards() {
            this.extraData.suppressRewards = !this.extraData.suppressRewards;
        },
        ToggleWaiveForAnnual() {
            this.extraData.waiveForAnnual = !this.extraData.waiveForAnnual;
        },
        TogglePortal() {
            this.extraData.patientPortal = !this.extraData.patientPortal;
        },
        async LoadPlan() {
            let returnData = await GetPlanFromDB(this.planKey);
            if (returnData === 404) {
                this.gotLoad404 = true;
                this.callReturned = true;
                return;
            }
            else if (returnData === 500) {
                this.callReturned = true;
                this.gotLoad500 = true;
                return;
            }
            //console.log("SubmitPlan.LoadPlan.returnData = ", returnData);
            //console.log("returnData = ", returnData);
            this.localPlan = JSON.parse(returnData.plans);
            this.extraData.monthlyFee = this.localPlan.dentalMenuFee;
            this.planID = returnData.id;
            this.setEverything(this.localPlan);
            this.monthlyFeeString = String(this.extraData.monthlyFee);
        
            this.practiceName = this.localPlan.practiceName;
            this.callReturned = true;
            this.isSubmitted = returnData.submitted;
            this.extraData.isPediatric = this.localPlan.isPediatric;
        },
        DisplayWarn() {
            this.showWarn = true;
        },
        RemoveWarn() {
            this.showWarn = false;
        }
    },
    mounted() {
        this.LoadPlan();
    }
}
</script>

<style scoped>
.full-page {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-teal-background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.window {
    background-color: white;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: 3px solid var(--font-color-teal);
    padding: 2em;
}

.top-text {
    color: var(--font-color-teal);
    font-size: 3em;
}

.subtitle {
    color: var(--color-dark-blue);
    font-size: 1.2em;
    margin-bottom: 1.5em;
}

.two-column-row {
    display: flex;
    flex-direction: row;
    width: 75%;
    justify-content: space-between;
    font-size: 1.2em;
    color: var(--color-dark-blue);
    margin: 0.5em;
}

.attribute-name {
    flex: 1;
}

.input-class {
    font-size: 1.05em;
    flex: 1;
    width: 100%;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 5px;
}

.input-class:hover {
    outline: none;
    border: 2px solid var(--color-light-blue);
}

.input-class:focus {
    outline: none;
}

.submit-btn {
    background-color: transparent;
    outline: none;
    border: 3px solid var(--color-medium-light-gray);
    font-size: 1.5em;
    border-radius: 50px;
    cursor: pointer;
    padding: 0.25em 1em;
    color: var(--color-dark-blue);
    font-weight: 900;
    margin-top: 1em;
    text-align: center;
}

.submit {
    margin-top: 1em;
    text-align: center;
    font-weight: 900;
}

.submit-error {
    color: var(--color-medium-red);
}

.submit-success {
    color: var(--color-bright-blue);
    font-size: 2em;
}

.checkbox-container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-hover-border:hover {
    border: none;
}

.checkbox {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.2em;
    left: 2px;
    top: -3px;
}

.submit-btn:hover, .submit-btn:focus {
    border: 3px solid var(--color-dark-blue)
}

.lds-dual-ring {
  display: inline-block;
  width: 40%;
  height: 40%;
  margin-left: 4.5em;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 200px;
  height: 200px;
  margin: 8px;
  border-radius: 50%;
  border: 12px solid transparent;
  border-color: var(--color-dark-blue) transparent var(--color-dark-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>