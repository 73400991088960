<template>
    <div :class="circleClass">
      <span class="number no-select">{{ number }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      number: {
        type: Number,
        required: true
      },
      backgroundColor: {
        type: String,
        default: 'gray'
      }
    },
    computed: {
      circleClass() {
        return {
          'circle': true,
          'blue-bg': this.backgroundColor === 'blue',
          'gray-bg': this.backgroundColor === 'gray'
        }
      }
    }
  };
  </script>
  
  <style>
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .blue-bg {
    background-color: var(--color-bright-blue)
  }
  
  .gray-bg {
    background-color: var(--color-medium-gray)
  }
  
  .number {
    z-index: 1;
  }
  </style>
