<template>
    <div class="full-modal">
        <div class="btn-exit grow-medium" @click="ExitModal"><i class="pi pi-times"></i></div>
        <h1 class="top-text">Add Benefit</h1>
        <div class="add-new">
            <div class="data-row">
                <h4 class="data-row-text">Name:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.benefit.name === '' && this.userTriedToClick)}" type="text" v-model="benefit.name"/>
            </div>
            <!-- <div class="data-row">
                <h4 class="data-row-text">Technical Name:</h4>
                <input class="data-row-input" type="text" v-model="benefit.technicalName"/>
            </div> -->
            <div class="data-row">
                <h4 class="data-row-text">Code:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.benefit.code === '' && this.userTriedToClick)}" type="text" v-model="benefit.code"/>
            </div>
            <div class="data-row">
                <h4 class="data-row-text">UCR:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.benefit.UCR === 0 && this.userTriedToClick)}" type="text" v-model="benefit.UCR" @input="ValidatePrice($event, 'UCR')"/>
            </div>
                <button class="submit-btn hover-grow" @click="AddNewBenefit">Add Benefit</button>
            </div>
            <p class="error-message" :class="{ 'hidden': !hasDefaultValues || !userTriedToClick }">Please fill out all fields to add this benefit</p>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        planType: String
    },
    data() {
        return {
            benefit: {
                name: "",
                code: "",
                UCR: 0,
            },
            userTriedToClick: false,
            inputClasses: {
                'data-row-input': true,
                'hover-grow': true,
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscape);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEscape);
    },
    methods: {
        ...mapActions(['addBenefitStore', 'updateBenefitByIDStore']),
        ExitModal() {
            this.$emit('exitModal');
        },
        AddNewBenefit() {
            this.userTriedToClick = true;
            if(this.hasDefaultValues) {
                return;
            }
            let newBenefit = this.benefit;
            newBenefit['planTypes'] = [];
            newBenefit['prices'] = {};
            newBenefit['frequencies'] = {};
            this.addBenefitStore(newBenefit)
            this.ExitModal();
        },
        ValidatePrice(event, fieldName) {
            const cleanedPrice = event.target.value.replace(/\D/g, '');
            this.benefit[fieldName] = parseInt(cleanedPrice, 10) || 0;
        },
        handleEscape(event) {
            if(event.key === 'Escape') {
                this.ExitModal();
            }
        },
    },
    computed: {
        hasDefaultValues() {
            if(this.benefit.name === "" || this.benefit.code === "" || this.benefit.UCR === 0) {
                return true;
            }
            else {return false;}
        },
    },
    watch: {
    selectedDropDownItem(newVal, oldVal) {
        //console.log("New value = ", newVal)
        if(newVal === 'ADD_NEW') {
            this.createNewBenefit = true;
        }
    }
}
}
</script>

<style scoped>

.full-modal {
    position: relative;
    width: 30%;
    height: auto;
    max-height: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 3px solid var(--color-dark-blue);
    outline: 2px solid white;
    padding: 2%;
}

.btn-exit {
    position: absolute;
    top: 20px;
    right: 25px;
    color: red;
    font-size: 1.75em;
    cursor: pointer;
    font-family: sans-serif
}

.top-text {
    color: var(--font-color-teal);
    margin-top: 0.25em;
}

.add-existing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

select {
    background-color: white;
    border: 2px solid var(--color-light-gray);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

select:hover {
    border: 2px solid var(--color-light-blue);
}

.add-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.data-row{
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 50px;
}

.data-row-text {
    font-size: 1.2em;
}

.data-row-input {
    height: 20px;
    border-radius: 5px;
    border: 2px solid var(--color-light-blue);
}

.input-incorrect {
    border: 2px solid var(--color-light-red) !important;
}

.data-row-input:focus {
    outline: none;
    border: 2px solid var(--color-bright-blue);
}

.checkbox {
    position: relative;
    margin-right: 9.4em;
    margin-bottom: 2em;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 2.25em;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.4em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
    margin-top: 1em;
    box-shadow: 3px 3px 5px var(--color-light-gray);
}

.submit-btn:active {
    border: 3px solid var(--color-dark-blue)
}

.error-message {
    margin: 0;
    margin-top: 1em;
    color: var(--color-medium-red);
}

.error-message.hidden {
    visibility: hidden;
}
</style>