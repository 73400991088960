<template>
  <div style="width: 100vw; height: 60vh; display: flex; align-items: center; justify-content: center;">
    <div class="window status-404" v-if="is404">
      <span class="title">Plan not found</span>
      <span class="subtitle">We apologize, but we're unable to find your plan. Please double-check your plan code or contact our data team at <a href="mailto:developer@thedentalmenu.com" style="color:var(--color-bright-blue)">developer@thedentalmenu.com</a> to find your plan!</span>
    </div>
    <div class="window status-404" v-else-if="is500">
      <span class="title">Plan not found</span>
      <span class="subtitle">We apologize, but there's been an error retrieving your plan. If this issue persists, please contact our data team at <a href="mailto:developer@thedentalmenu.com" style="color:var(--color-bright-blue)">developer@thedentalmenu.com</a> and we'll get everything sorted out for you!</span>
    </div>
    <div class="window" v-else>
        <h1 class="text">Loading Plan...</h1>
        <div class="lds-dual-ring"></div>
    </div>
  </div>
  
</template>

<script>
export default {
  props: {
    getPlanStatus: Number,
  },
    mounted() {
        //console.log("Emitting Load Event");
        this.$emit("LoadPlan");
    },
    computed: {
      is404() {
        return this.getPlanStatus === 404;
      },
      is500() {
        return this.getPlanStatus === 500;
      }
    }
}
</script>

<style scoped>

.window {
    margin-top: 10%;
    width: 50%;
    height: 40%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 20px;
}

.status-404 {
  width: 35vw;
  height: 300px !important;
  color: var(--font-color-teal);
  justify-content: center;
}

.title {
  font-size: 3em;
}

.subtitle {
  margin-top: 4em;
  color: var(--color-dark-blue);
  font-size: 1.2em;
  text-align: center;
  line-height: 1.5em;
  width: 90%;
}

.text {
    color: var(--font-color-teal);
    margin-top: 10%;
    margin-bottom: 8%;
    margin-left: 1%;
}

.lds-dual-ring {
  display: inline-block;
  width: 40%;
  height: 40%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 200px;
  height: 200px;
  margin: 8px;
  border-radius: 50%;
  border: 12px solid transparent;
  border-color: var(--color-dark-blue) transparent var(--color-dark-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>