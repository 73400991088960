<template>
    <div :class="AddOnClass" @click="toggleChecked">
        <div class="the-rest">
            <div class="top-row">
                <div class="checkbox-container grow-medium" style="position:relative;">
                    <div class="checkbox" @click="toggleChecked($event)">
                        <div class="checkbox-fill" v-if="this.localAddOn.isActive">
                            <p class="checkbox-text">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer"></div>
            <h4 class="edit-msg">Title- Type to Edit</h4>
            <div class="middle">
                <textarea ref="textarea1"
                class="title-input add-on-input hover-grow"
                :class="{'input-incorrect': (this.userTriedToCheck === true && !titleCorrect)}"
                v-model="localAddOn.title"
                @input="textUpdated"
                @mousedown="setLastInteractionType('mouse')" 
                @keydown="setLastInteractionType('keyboard')"
                @click="StopClickPropagation"></textarea>
            </div>
            <h4 class="edit-msg">Description- Type to Edit</h4>
            <div class="middle">
                <textarea ref="textarea2"
                class="description-input add-on-input hover-grow"
                :class="{'input-incorrect': (this.userTriedToCheck === true && !descriptionCorrect)}"
                v-model="localAddOn.description"
                @input="textUpdated"
                @focus="handleFocus"
                @click="StopClickPropagation"></textarea>
            </div>
            <h4 class="edit-msg">Benefits</h4>
            
            <div class="benefits">
                <div class="benefit-row" v-for="benefit in addOnBenefits" :key="benefit.id">
                    <!-- <input class="benefit-text" v-model="benefit.name"/> -->
                    <p class="benefit-name" @click="emitShowEditBenefitModal(benefit)">{{ benefit.name }}</p>
                    <p class="benefit-ucr" v-if="benefit.UCR != null">UCR ${{ benefit.UCR }}</p>
                    <p class="benefit-ucr" v-else></p>
                    <div style="display: flex; justify-content: center; align-items: center; flex: 1;">
                        <i class="pi pi-trash delete grow-medium" @click="RemoveBenefit($event, benefit.id)"></i>
                    </div>
                </div>
            </div>
            <button class="add-benefit-btn grow-medium" :class="{'add-btn-incorrect': (this.userTriedToCheck && !buttonCorrect)}" @click="addAddOnBenefit($event)">+ Add Benefit</button>
            <div class="warning">
                <p v-if="displayWarning">Fill out all fields to create this Add-On</p>
            </div>
        </div>
        <span class="bottom-text">$<input type="text" ref="priceInput" class="price-input hover-grow" :class="{'input-incorrect': (this.userTriedToCheck === true && !priceCorrect)}" v-model="localAddOn.price" @input="validatePrice" @click="StopClickPropagation"/> &nbsp;/ Month</span>
        
    </div>
</template>

<script>
//import CustomCheckbox from './CustomCheckbox.vue'
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
export default {
    
    props: {
        addOn: Object,
        benefits: Array,
    },
    data() {
        return {
            localAddOn: {
                title: "",
                description: "",
                isActive: null,
                price: 0,
            },
            checkboxHeight: 23,
            shouldCreateNew: false,
            lastInteractionType: null,
            userTriedToCheck: false,
            showModal: false,
            benefitToEdit: null,
        }
    },
    methods: {
        ...mapActions(['updateBenefitByIDStore', 'updateAddOnByID', 'removeBenefitFromAddOnStore']),
        textUpdated(event) {
            this.adjustTextareaHeight(event);
            this.UpdateAddOn();
        },
        toggleChecked(event) {
            this.userTriedToCheck = false;
            if(event) {
                this.StopClickPropagation(event)
            }
            
            if(this.hasDefaultValues) {
                this.userTriedToCheck = true;
            }
            else {
                this.localAddOn.isActive = !this.localAddOn.isActive
                this.UpdateAddOn();
            }
        },
        adjustTextareaHeight(event) {
            const element = event.target;
            const style = window.getComputedStyle(element);
            // Extracting padding and border values from the computed style
            const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10);
            const paddingBottom = parseInt(style.getPropertyValue('padding-bottom'), 10);
            const borderTop = parseInt(style.getPropertyValue('border-top-width'), 10);
            const borderBottom = parseInt(style.getPropertyValue('border-bottom-width'), 10);
            // Reset height to 0 to allow shrinking, then set to scrollHeight plus padding and border
            element.style.height = '0';
            const newHeight = element.scrollHeight + paddingTop + paddingBottom + borderTop + borderBottom;
            element.style.height = `${newHeight - 10}px`;
            this.checkCreateNew();
        },
        adjustInputWidth() {
            const input = this.$refs.priceInput;
            if (input) {
                const priceLength = this.localAddOn.price.toString().length;
                const newWidth = Math.max(priceLength * 17, 34);
                input.style.width = `${newWidth}px`;
            }
        },
        validatePrice() {
            const cleanedPrice = this.localAddOn.price.replace(/\D/g, '');
            this.localAddOn.price = parseInt(cleanedPrice, 10) || 0;
            this.adjustInputWidth();
            this.checkCreateNew();
            this.UpdateAddOn();
        },
        checkCreateNew() {
            //console.log("Entering CheckCreateNew");
            if(this.shouldCreateNew === true && this.localAddOn.title != "Type to Create New Add-On" && this.localAddOn.description != "Your description goes here." && this.localAddOn.price != 0) {
                this.shouldCreateNew = false;
                this.$emit('createAddOn');
            }
        },
        handleFocus(event) {
            if (this.lastInteractionType === 'keyboard') {
                event.target.select();
            }
        },
        setLastInteractionType(type) {
            this.lastInteractionType = type;
        },
        addAddOnBenefit(event) {
            this.StopClickPropagation(event)
            this.$emit('showModal', {id: this.addOn.id})
        },
        StopClickPropagation(event) {
            event.stopPropagation();
        },
        RemoveBenefit(event, benefitId) {
            this.StopClickPropagation(event)
            this.removeBenefitFromAddOnStore({ benefitId: benefitId, addOnId: this.addOn.id });
        },
        UpdateAddOn() {
            let updatedAddOn = cloneDeep(this.localAddOn);
            updatedAddOn.benefits = this.addOn.benefits;
            updatedAddOn.id = this.addOn.id;
            this.updateAddOnByID(updatedAddOn);
        },
        GetAddOnBenefits() {
            let benefits = [];
                this.addOn.benefits.forEach((benefitID) => {
                    benefits.push(this.$store.getters.getBenefitByID(benefitID));
                })
                return benefits;
        },
        emitShowEditBenefitModal(benefit) {
            console.log("Entering emitShowEditBenefit in AddOn")
            this.$emit('edit-benefit', benefit);
        }
    },
    computed: {
        spacerHeight() {
            return `${this.checkboxHeight * 2}px`
        },
        AddOnClass() {
            return {
                'add-on': true,
                'hover-grow': true,
                'add-on-checked': this.localAddOn.isActive === true,
                'add-on-not-checked': this.localAddOn.isActive === false,
            }
        },
        addOnBenefits: {
            get() {
                //let result = this.$store.getters.getAddOnBenefits(this.addOn.id);
                let benefits = [];
                this.benefits.forEach((benefitID) => {
                    benefits.push(this.$store.getters.getBenefitByID(benefitID));
                })
                return this.GetAddOnBenefits();
            },
            set() {
                return
            }
        },
        titleCorrect() {
            return this.localAddOn.title !== "Create Your Own"
        },
        descriptionCorrect() {
            return this.localAddOn.description !== "Your description goes here"
        },
        priceCorrect() {
            return this.localAddOn.price > 0;
        },
        buttonCorrect() {
            return this.addOnBenefits.length > 0;
        },
        hasDefaultValues() {
            if(!this.titleCorrect || !this.descriptionCorrect || !this.priceCorrect || !this.buttonCorrect) {
                return true;
            }
            return false;
        },
        displayWarning() {
            return (this.hasDefaultValues && this.userTriedToCheck)
        },
    },
    created() {
        this.localAddOn.isActive = this.addOn.isActive;
        this.localAddOn.title = this.addOn.title;
        this.localAddOn.description = this.addOn.description
        this.localAddOn.price = parseInt(this.addOn.price, 10) || 0;
    },
    mounted() {
        this.$nextTick(() => {
            const textareas = this.$el.querySelectorAll('.description-input');
            textareas.forEach(textarea => {
                this.adjustTextareaHeight({target: textarea});
            });
        });
            this.adjustInputWidth();
            if(this.localAddOn.title === "Create Your Own" && this.localAddOn.price === 0) {
                this.shouldCreateNew = true;
            }
    },
}
</script>

<style scoped>
.add-on {
    background-color: var(--color-super-light-gray);
    width: 30%;
    min-height: 45%;
    margin: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 3px 3px 5px var(--color-light-gray);
}

.add-on-checked {
    border: 2px solid var(--color-bright-blue);
    box-shadow: 2px 2px 5px var(--color-bright-blue-shadow);
}

.add-on-not-checked {
    border: 1px solid var(--color-medium-gray);
    padding: 1px;
}

.top {
    background-color: var(--color-bright-blue);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 75%;
}

.spacer {
    height: v-bind('spacerHeight')
}

.edit-msg {
    margin: 0;
    margin-left: 5%;
    margin-top: 3px;
    margin-bottom: 3px;
    color: var(--color-dark-gray);
    font-size: 0.9em;
}

.top-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.checkbox {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 25px;
    height: 25px;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
}

.checkbox-fill {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    position: absolute;
    font-size: 1.5em;
    left: 2px;
    top: -3px;
}

.middle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-input {
    font-size: 1.3em;
    border: 2px solid var(--color-medium-light-gray);
}


.input-incorrect {
    border: 2px solid rgba(255,0,0,0.3) !important;
}

.description-input {
    font-size: 1em;
    border: 2px solid var(--color-medium-light-gray);
}

textarea {
    width: 90%;
    max-width: 90%;
    background-color: white;
    overflow: auto;
    border-radius: 5px;
    resize: none;
    font-family: inherit;
    color: black;
}

.add-on-input:focus, .add-on-input:hover {
    border: 2px solid var(--color-light-blue);
    outline: none;
    border-radius: 6px;
}

.benefits {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.benefit-row {
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    padding-bottom: 2%;
    margin-bottom: 2%;
    border-bottom: 1px solid var(--color-medium-gray);
}

p {
    margin: 0.25em;
}

.benefit-name {
    display: flex;
    flex: 5;
    margin-left: 4%;
    border: 0px solid var(--color-medium-light-gray);
    border-radius: 5px;
    font-size: 0.85em;
    cursor: pointer;
    background-color: transparent;
}

.benefit-ucr {
    display: flex;
    flex: 3;
    margin-left: 0;
    font-size: 0.85em;
}

.add-benefit-btn {
    margin-top: 4%;
    margin-left: 4%;
    font-size: 1em;
    background-color: white;
    border: 2px solid var(--color-light-blue);
    color: var(--color-bright-blue);
    font-weight: 900;
    padding: 0.2em;
    padding-left: 0.4em;
    padding-right: 0.5em;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 4%;
}

.add-btn-incorrect {
    border: 2px solid var(--color-light-red)
}

.bottom {
    height: 25%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
}

.warning {
    width: 95%;
    text-align: center;
    margin: 0;
    color: var(--color-medium-red);
    font-size: 0.8em;
}

.bottom-text {
    color: black;
    font-size: 1.2em;
    padding-left: 1em;
    font-weight: 900;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.price-input {
    border: none;
    outline: none;
    background-color: white;
    font-size: inherit;
    font-size: 1.4em;
    margin: 0;
    margin-bottom: 0.2em;
    margin-left: 2px;
    color: var(--color-bright-blue);
    padding: 0;
    border: 2px solid var(--color-medium-light-gray);
    text-align: center;
}

.price-input:hover {
    border: 2px solid var(--color-light-blue);
    outline: none;
    border-radius: 6px;
}

.price-input:focus {
    border: 2px solid var(--color-bright-blue);
    outline: none;
    border-radius: 6px;
}

</style>