<template>
    <div class="full-modal">
        <div class="btn-exit grow-medium" @click="ExitModal"><i class="pi pi-times"></i></div>
        <h1 class="top-text">Edit Benefit</h1>
        <div class="add-new">
            <div class="data-row">
                <h4 class="data-row-text">Name:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.benefit.name === '' && this.userTriedToClick)}" type="text" v-model="benefit.name"/>
            </div>
            <div class="data-row">
                <h4 class="data-row-text">Code:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.benefit.code === '' && this.userTriedToClick || !this.isCodeValid)}" type="text" v-model="benefit.code" @input="limitCodeLength"/>
            </div>
            <div class="data-row" v-if="this.mode !== 'addon'">
                <h4 class="data-row-text">With each visit?</h4>
                <div class="checkbox-container grow-medium" style="position:relative;">
                    <div class="checkbox" @click="handleCheckboxClick()">
                        <div class="checkbox-fill" v-if="this.benefitFrequency === -1">
                            <p class="checkbox-text">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-row" v-if="this.benefitFrequency !== -1 && this.mode !== 'addon'">
                <h4 class="data-row-text no-select">Frequency:</h4>
                <div>
                    Every &nbsp;<input class="data-row-input hover-grow" style="width: 2.5em;" :class="{'input-incorrect': (this.benefit.price === 0 && this.userTriedToClick)}" type="text" v-model="benefit.frequencies[this.planType]" @input="ValidateFrequency($event)"/> &nbsp;Months
                </div>
            </div>
            <div class="data-row">
                <h4 class="data-row-text">UCR:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.benefit.UCR === 0 && this.userTriedToClick)}" type="text" v-model="benefit.UCR" @input="ValidateUCR($event)"/>
            </div>
            <div class="data-row" v-if="mode !== 'addon'">
                <h4 class="data-row-text">Desired Payout:</h4>
                <input class="data-row-input hover-grow" type="text" v-model="benefit.prices[planType]" @input="ValidatePrice($event)"/>
            </div>
                <button class="submit-btn hover-grow" @click="SaveBenefit">Save</button>
                <div style="height: 5%"></div>
            </div>
            <p class="error-message" :class="{ 'hidden': !(hasDefaultValues && userTriedToClick) || !isCodeValid }">{{ errorMessage }}</p>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        propBenefit: Object,
        planType: Number,
        mode: String
    },
    data() {
        return {
            benefit: null,
            selectedOption: "Add Existing Benefit",
            createNewBenefit: false,
            selectedDropDownItem: "",
            userTriedToClick: false,
            inputClasses: {
                'data-row-input': true,
                'hover-grow': true,
            }
        }
    },
    created() {
        window.addEventListener('keydown', this.handleEscape);
        this.benefit = JSON.parse(JSON.stringify(this.propBenefit));
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEscape);
    },
    methods: {
        ...mapActions(['addBenefitStore', 'updateBenefitByIDStore']),
        ExitModal() {
            this.$emit('exitModal');
        },
        
        SaveBenefit() {
            this.userTriedToClick = true;
            if(this.hasDefaultValues) {
                return;
            }
            //console.log("Entering updateByID in EditDetailsModal")
            this.updateBenefitByIDStore(this.benefit)
            this.$emit('recalculatePrices');
            this.ExitModal();
        },
        ValidatePrice(event) {
            const cleanedPrice = event.target.value.replace(/\D/g, '');
            this.benefit.prices[this.planType] = parseInt(cleanedPrice, 10) || 0;
        },
        ValidateUCR(event) {
            const cleanedPrice = event.target.value.replace(/\D/g, '');
            this.benefit.UCR = parseInt(cleanedPrice, 10) || 0;
        },
        ValidateFrequency(event) {
            const cleanedFrequency = event.target.value.replace(/\D/g, '');
            this.benefit.frequencies[this.planType] = parseInt(cleanedFrequency, 10) || 0;
        },
        handleEscape(event) {
            if(event.key === 'Escape') {
                this.ExitModal();
            }
        },
        handleCheckboxClick() {
            if(this.benefitFrequency === -1) {
                this.benefit.frequencies[this.planType] = 6
            }
            else {
                this.benefit.frequencies[this.planType] = -1
            }
        },
        limitCodeLength() {
            if (this.benefit.code.length > 5) {
                this.benefit.code = this.benefit.code.slice(0, 5);
            }
        }
    },
    computed: {
        codes() {
            let result = this.$store.state.benefits
            result = result.filter(benefit => !benefit.planTypes.includes(this.planType))
            return result
        },
        hasDefaultValues() {
            if(this.benefit.name === "" || !this.isCodeValid || this.benefit.UCR === 0) {
                return true;
            }
            else {return false;}
        },
        benefitFrequency() {
            return this.benefit.frequencies[this.planType];
        },
        isCodeValid() {
            const regex = /^D\d{4}$/;
            return regex.test(this.benefit.code);
        },
        errorMessage() {
            if(!this.isCodeValid) {
                return "Please enter a valid code (D####)"
            }
            else {
                return "Please fill out all fields to add this benefit"
            }
        }
    },
}
</script>

<style scoped>

.full-modal {
    position: relative;
    width: 30%;
    height: 45%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 3px solid var(--color-dark-blue);
    outline: 2px solid white;
    padding: 2%;
}

.btn-exit {
    position: absolute;
    top: 20px;
    right: 25px;
    color: red;
    font-size: 1.75em;
    cursor: pointer;
    font-family: sans-serif
}

.top-text {
    color: var(--font-color-teal);
    margin-top: 0.25em;
}

.add-existing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

select {
    background-color: white;
    border: 2px solid var(--color-light-gray);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

select:hover {
    border: 2px solid var(--color-light-blue);
}

.add-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.data-row{
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3em;
}

.data-row-text {
    font-size: 1.2em;
}

.data-row-input {
    height: 20px;
    border-radius: 5px;
    border: 2px solid var(--color-light-blue);
}

.input-incorrect {
    border: 2px solid var(--color-light-red) !important;
}

.data-row-input:focus {
    outline: none;
    border: 2px solid var(--color-bright-blue);
}

.checkbox {
    position: relative;
    margin-right: 9.4em;
    margin-bottom: 2em;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 2.25em;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.4em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
    margin-top: 1em;
    box-shadow: 3px 3px 5px var(--color-light-gray);
}

.submit-btn:active {
    border: 3px solid var(--color-dark-blue)
}

.error-message {
    margin: 0;
    color: var(--color-medium-red);
}

.error-message.hidden {
    visibility: hidden;
}

.checkbox-container {
    cursor: pointer;
    position: relative;
    width: auto;
    height: 25px;
    margin-right: 85px;
}

.checkbox {
    position: absolute;
    width: 25px;
    height: 25px;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    margin: 0;
}

.checkbox-fill {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    position: absolute;
    font-size: 1.5em;
    left: 2px;
    top: -3px;
}
</style>