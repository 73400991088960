<template>
    <div class="full-modal">
        <div class="btn-exit grow-medium" @click="ExitModal"><i class="pi pi-times"></i></div>
        <h1 class="top-text">Add Discount</h1>
        <div class="switch-container">
            <p class="no-select">Membership</p>
            <div class="switch grow-medium no-select"  :class="{'switch-alt': (discountType !== 'monthly')}" @click="ToggleDiscountType()">
                <img src="../../../../../public/slider-thumb.png" class="switch-bar grow-medium" :style="{transform: `translateX(${discountType !== 'monthly' ? '' + (translationDistance - 3) + 'px' : '0px'})`}">
            </div>
            <p class="no-select">Treatment</p>
        </div>
        <div class="add-new">
            <div class="data-row">
                <h4 class="data-row-text">Name:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.discount.title === '' && this.userTriedToClick)}" type="text" v-model="discount.title"/>
            </div>
            <div class="data-row">
                <h4 class="data-row-text">Description:</h4>
                <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.discount.description === '' && this.userTriedToClick)}" type="text" v-model="discount.description"/>
            </div>
            <div class="data-row">
                <h4 class="data-row-text" v-if="discountType === 'monthly'">Dollars Off Per Month:</h4>
                <h4 class="data-row-text" v-else>Percent Off:</h4>
                <div style="display: flex; align-items: center; position: relative;">
                    <p class="symbol" style="left: -15px;" v-if="discountType === 'monthly'">$ &nbsp;</p>
                    <input class="data-row-input hover-grow" :class="{'input-incorrect': (this.discount.discountAmount === 0 && this.userTriedToClick)}" type="text" v-model="discount.discountAmount" @input="ValidatePrice($event)"/>
                    <p class="symbol" style="right: -20px;" v-if="discountType !== 'monthly'">&nbsp;%</p>
                </div>
            </div>
            <button class="submit-btn hover-grow" @click="AddNewDiscount">Add Discount</button>
            <div style="height: 7%"></div>
        </div>
        <p class="error-message" :class="{ 'hidden': !hasDefaultValues || !userTriedToClick }">Please fill out all fields to add this discount</p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        planType: String
    },
    data() {
        return {
            discount: {
                title: "",
                description: "",
                discountAmount: 0,
            },
            inputClasses: {
                'data-row-input': true,
                'hover-grow': true,
            },
            discountType: "monthly",
            userTriedToClick: false,
            translationDistance: 0,
        }
    },
    mounted() {
        window.addEventListener('resize', this.CalculateSwitchBarPosition);
        window.addEventListener('keydown', this.handleEscape);
        this.CalculateSwitchBarPosition();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.CalculateSwitchBarPosition);
        window.removeEventListener('keydown', this.handleEscape);
    },
    methods: {
        ...mapActions(['addBenefitStore', 'updateBenefitByIDStore']),
        ExitModal() {
            this.$emit('exitModal');
        },
        AddNewDiscount() {
            this.userTriedToClick = true;
            if(this.hasDefaultValues) {
                return;
            }
            let newDiscount = JSON.parse(JSON.stringify(this.discount));
            newDiscount.discountType = this.discountType;
            this.$emit('addDiscount', newDiscount);
            this.ExitModal();
        },
        ValidatePrice(event) {
            const cleanedPrice = event.target.value.replace(/\D/g, '');
            this.discount.discountAmount = parseInt(cleanedPrice, 10) || 0;
        },
        ToggleDiscountType() {
            if(this.discountType === 'monthly') {
                this.discountType = 'treatment';
            }
            else {
                this.discountType = 'monthly';
            }
            this.CalculateSwitchBarPosition();

        },
        handleEscape(event) {
            if(event.key === 'Escape') {
                this.ExitModal();
            }
        },
        CalculateSwitchBarPosition() {
            this.$nextTick(() => {
                const switchElement = this.$el.querySelector('.switch');
                const switchWidth = switchElement.offsetWidth;
                // Adjust this calculation as needed based on the switch bar size and desired offsets
                this.translationDistance = switchWidth - (this.$el.querySelector('.switch-bar').offsetWidth + 4); // Example adjustment
            });
        },
    },
    computed: {
        hasDefaultValues() {
            if(this.discount.title === "" || this.discount.code === "" || this.discount.discountAmount === 0) {
                return true;
            }
            else {return false;}
        },
    },
}
</script>

<style scoped>

.full-modal {
    position: relative;
    width: 30%;
    height: 55%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 3px solid var(--color-dark-blue);
    outline: 2px solid white;
    padding: 2%;
}

.btn-exit {
    position: absolute;
    top: 20px;
    right: 25px;
    color: red;
    font-size: 1.75em;
    cursor: pointer;
    font-family: sans-serif
}

.top-text {
    color: var(--font-color-teal);
    margin-top: 0.25em;
}

.switch-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
}

.switch {
    position: relative;
    display: flex;
    width: 10%;
    height: 28px;
    border: 1px solid var(--color-dark-blue);
    background-color: var(--color-light-blue-shadow);
    border-radius: 14px;
    margin-left: 0.7em;
    margin-right: 0.7em;
    overflow: hidden;
    cursor: pointer;
}

.switch-alt {
    justify-content: flex-end;
}

.switch-bar {
    position: absolute;
    height: 24px;
    top: 2px;
    left: 2px; /* Set a starting position */
    transition: transform 0.2s ease;
}


select {
    background-color: white;
    border: 2px solid var(--color-light-gray);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

select:hover {
    border: 2px solid var(--color-light-blue);
}

.add-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.data-row{
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 16%;
}

.data-row-text {
    font-size: 1.2em;
    font-weight: 100;
}

.data-row-input {
    height: 20px;
    border-radius: 5px;
    border: 2px solid var(--color-light-blue);
}

.input-incorrect {
    border: 2px solid var(--color-light-red) !important;
}

.data-row-input:focus {
    outline: none;
    border: 2px solid var(--color-bright-blue);
}

.symbol {
    color: var(--color-bright-blue);
    position: absolute;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 2.25em;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.4em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
    margin-top: 1em;
    box-shadow: 3px 3px 5px var(--color-light-gray);
}

.submit-btn:active, .submit-btn:focus {
    border: 3px solid var(--color-dark-blue);
    outline: none;
}

.error-message {
    margin: 0;
    color: var(--color-medium-red);
}

.error-message.hidden {
    visibility: hidden;
}
</style>