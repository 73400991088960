import { render, staticRenderFns } from "./AddDiscountModal.vue?vue&type=template&id=152d8368&scoped=true"
import script from "./AddDiscountModal.vue?vue&type=script&lang=js"
export * from "./AddDiscountModal.vue?vue&type=script&lang=js"
import style0 from "./AddDiscountModal.vue?vue&type=style&index=0&id=152d8368&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152d8368",
  null
  
)

export default component.exports