  <template>
    <div :class="['content hover-grow', isChecked ? 'plan-selected' : 'plan-not-selected']" @click="toggleChecked">
      <div>
          <div class="checkbox">
              <div class="checkbox-fill" v-if="isChecked">
                  <p class="checkbox-text">✓</p>
              </div>
          </div>
      </div>
      <div class="top-text no-select">
        Every <input class="num-months hover-grow" v-model="frequency" type="text" @click="StopClickPropagation" :style="{ width: (inputWidth) + 'ch'}" @input="processInput()"/> Months
      </div>
      <div class="checkbox-row monthly" v-if="this.isChecked">
        <div class="info-text">
          Monthly Price:
        </div>
        <div class="plan-item">
            $<input type="text" ref="priceInput" data-type="monthly" class="price-input plan-input grow-medium" v-model="monthlyPrice" @input="updateMonthlyPrice" @click="StopClickPropagation"/>
        </div>
      </div>
      <div class="line" style="margin-bottom: 0.2em;"></div>

      <div class="bottom-half">
        
        <div class="checkbox-row" style="margin-bottom: 0.2em;" v-if="this.isChecked">
          <div class="info-text">
            Annual Price:
          </div>
          <div class="plan-item">
              $<input type="text" ref="priceInput" data-type="annual" class="price-input plan-input grow-medium" v-model="annualPrice" @input="updateAnnualPrice" @click="StopClickPropagation"/>
          </div>
        </div>
        <!-- <div class=checkbox-row>
          <div class="info-text">
            Include Dental Menu Fee:
          </div>
          <div class="checkbox-container plan-item" >
            <input type="checkbox" v-model="includeDMFee" @click="updateIncludeDMFee" class="custom-checkbox grow-large">
          </div>
        </div>
        <div class=checkbox-row style="border-bottom: none;">
          <div class="info-text">
            Include Processing Fee:
          </div>
          <div class="checkbox-container plan-item">
            <input type="checkbox" v-model="includeStripeFee" @click="updateIncludeStripeFee" class="custom-checkbox grow-large">
          </div>
        </div> -->
        <div :class="flagClass">{{ flagText }}</div>
        <button class="reset-btn grow-large" @click="ResetPrices" v-if="plan.annualPriceAltered || plan.monthlyPriceAltered">Reset</button>
      </div>
    </div>
  </template>
  
  <script>
  //import CustomCheckbox from './CustomCheckbox.vue'
  import { mapActions } from 'vuex';
  import { InputToInteger, CalculateComputedPlanPrice } from '../utils/functions.js'
  export default {
    name: 'PlanComponent',
    components: {
      //CustomCheckbox
    },
    props: {
      plan: Object,
    },
    data() {
      return {
        isChecked: false,
        hasCompletedInitialLoad: false,
        frequency: null,
      };
    },
    methods: {
      ...mapActions(['updatePlan']),
      toggleChecked() {
        this.isChecked = !this.isChecked;
        let body = this.plan
        body.isActive = this.isChecked;
        this.updatePlan(body)
      },
      updateMonthlyPrice(event) {
        let price = InputToInteger(event.target.value)
        let newPlan = this.plan;
        newPlan.price = price;
        newPlan.monthlyPriceAltered = true;
        if(!newPlan.annualPriceAltered) {
          newPlan.annualPrice = price * 12;
        }
        this.updatePlan(newPlan);
      },
      updateAnnualPrice(event) {
        let annualPrice = InputToInteger(event.target.value)
        let newPlan = this.plan;
        newPlan.annualPrice = annualPrice;
        newPlan.annualPriceAltered = true;
        this.updatePlan(newPlan);
      },
      updateIncludeDMFee(event) {
        this.StopClickPropagation(event);
      },
      updateIncludeStripeFee(event) {
        this.StopClickPropagation(event);
      },
      StopClickPropagation(event) {
        event.stopPropagation();
      },
      ResetPrices(event) {
        this.StopClickPropagation(event);
        let newPlan = this.plan;
        newPlan.annualPriceAltered = false;
        newPlan.monthlyPriceAltered = false;
        this.updatePlan(newPlan);
        this.$emit('UpdatePriceFromPlan');
      },
      validatePrice(val) {
        let result = InputToInteger(val);
        this.frequency = result;
      },
      updateInputWidth() {
        this.inputWidth = this.frequency.toString().length > 0 ? this.frequency.toString().length : 1;
      },
      processInput() {
        if(this.frequency.length > 1) {
          this.frequency = this.frequency.substring(0,2)
        }
        this.frequency = InputToInteger(this.frequency);
        this.updateInputWidth();
        let newPlan = this.plan;
        newPlan.frequency = this.frequency;
        if(newPlan.monthlyPriceAltered) {
            return;
        }
        newPlan.price = CalculateComputedPlanPrice(this.$store, newPlan);
        if(!newPlan.monthlyPriceAltered && !newPlan.annualPriceAltered) {
          newPlan.annualPrice = newPlan.price * 12
        }
        this.updatePlan(newPlan);
      }
    },
    computed: {
      monthlyPrice: {
        get() {
          return this.plan.price
        },
        set() {
          return
        }
      },
      annualPrice: {
        get() {
          return this.plan.annualPrice
        },
        set() {
          return
        }
      },
      flagText() {
        if(this.plan.monthlyPriceAltered && !this.plan.annualPriceAltered) {
          return "Monthly price adjusted manually"
        }
        else if(!this.plan.monthlyPriceAltered && this.plan.annualPriceAltered) {
          return "Annual price adjusted manually"
        }
        else if(this.plan.monthlyPriceAltered && this.plan.annualPriceAltered) {
          return "Monthly & Annual prices adjusted manually"
        }
        else {
          return "____"
        }
      },
      flagClass() {
        return {
          'flags': true,
          'flags-line': this.flagText === "____",
          'no-select': true,
        }
      },
    },
    created() {
      this.isChecked = this.plan.isActive
      this.hasCompletedInitialLoad = true;
    },
    mounted() {
      this.frequency = this.plan.frequency;
      this.updateInputWidth();
    },
  };
  </script>
  
  <style scoped>
  .check {
    position: absolute;
    top: 5px;
    right: 12px;
    font-size: 1.67em;
    color: var(--color-dark-blue);
  }

  .checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
}

.checkbox-fill {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 25px;
    height: 25px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    position: absolute;
    font-size: 1.5em;
    left: 4px;
    top: -2px;
}
  
  .content {
    min-width: 45%;
    text-align: center;
    color: var(--color-dark-gray);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: var(--plan-corner-radius);
    box-shadow: 3px 3px 5px var(--color-light-gray);
    cursor: pointer;
  }

  .content-unchecked {
    min-width: 35%;
  }

  .plan-selected {
    border: 2px solid var(--color-dark-blue);
    box-shadow: 2px 2px 5px var(--color-dark-blue-shadow);
  }

  .plan-not-selected {
    transform: scale(0.90);
    border: 1px solid var(--color-medium-gray);
    padding: 1px;
    height: 75%;
  }

  .plan-not-selected.hover-grow:hover {
    transform: scale(0.918);
  }

  .num-months {
    background: none;
    border: 2px solid transparent;
    border-radius: 10px;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    width: auto;
    text-align: center;
    font-size:1.7em;
    font-weight: bold;
    margin: 0;
    width: 20px;
  }

  .num-months:hover {
    border: 2px solid var(--color-light-blue);
  }

  .num-months:focus {
    border: 2px solid var(--color-bright-blue);
    outline: none;
  }

  .top-text {
    font-size: 1.3em;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
  }

  .line {
    width: 80%; /* If you still want the line to be full width */
    display: block; /* Keep the line as a block element */
    border-top: 1px solid var(--color-medium-gray)
  }

  .bottom-half {
    display: flex;
    width: 100%;
    min-height: 75px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.25em;
  }
  
  .info-text {
    flex: 4;
    text-align: left;
  }

  .plan-item {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
  }

  .plan-input {
    font-size: 1.1em;
    max-width: 45px;
    min-height: 24px;
    margin: 0;
  }

  .checkbox-row {
    height: 40%;
    width: 80%;
    min-width: 210px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-medium-light-gray);
    margin-top: 3px;
    margin-bottom: 5px;
  }

.monthly {
  margin: 0;
  border-bottom: none;
  font-size: 1.3em;
}

  .flags {
    font-size: 0.8em;
    color: var(--color-dark-blue);
    margin-bottom: 0.25em;
  }

  .flags-line {
    font-family: "";
  }

  .reset-btn {
    background-color: transparent;
    outline: none;
    border: 2px solid var(--color-bright-blue);
    border-radius: 100px;
    color: var(--color-dark-blue);
    cursor: pointer;
  }
  

  </style>
  