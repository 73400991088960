<template>
    <div :class="pageClass">
        <HeaderBar/>
        <div class="header-spacer"></div>
        <div class="component-window">
            <component :is="components[currentComponent].name" v-bind="components[currentComponent].props" @emitSwap="advanceComponent($event)"></component>
        </div>        
    </div>
</template>

<script>
import HeaderBar from './Components/IntakeHeader.vue'
import StepOne from './Components/StepOne.vue'
import UCRs from '../Onboarding/components/UCRInfo.vue'
import Contact from '../Onboarding/components/ContactInfo.vue'
export default {
    components: {
        HeaderBar,
        StepOne,
        UCRs,
        Contact
    },
    data() {
        return {
            currentComponent: 0,
            userTriedToAdvanceLocal: false,
        }
    },
    computed: {
        components() {
            return [
                {
                    name: "StepOne",
                },
                {
                    name: "Contact",
                    props: {userTriedToAdvance: this.userTriedToAdvanceLocal, mode: "intake"}
                },
                {
                    name: "UCRs",
                    props: {userTriedToAdvance: this.userTriedToAdvanceLocal, mode: "intake"}
                }
            ]
        },
        pageClass() {
            return {
                "page": true,
                "page-two": this.currentComponent === 2 ? true : false,
                "contact": this.currentComponent === 1 ? true : false
            }
        },
        store() {
            return this.$store.state;
        }
    },
    methods: {
        advanceComponent(event) {
            const shouldGoForward = event.shouldGoForward;
            this.userTriedToAdvanceLocal = true;
            if(this.currentComponent === 1 && !this.$store.getters.contactPageFilled('intake')) {
                return;
            }
            if (shouldGoForward) {
                this.currentComponent++;
                this.userTriedToAdvanceLocal = false;
            }
        }
    }
}
</script>

<style scoped>

html, body {
    margin: 0;
    padding: 0;
}

.header-spacer {
    height: var(--header-height)
}

.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-teal-background);
    overflow-y: auto;
    margin: 0;
    padding: 0;
}

.page-two {
    height: 135vh;
}

.contact {
    height: 100vh;
}

.component-window {
    height: 90%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3em;
  }
</style>