<template>
    <div id="window-1">
        <div id="top-text-1">
            <h1>Let's Get Started</h1>
        </div>
        <div id="input-container">
            <div class="input-wrapper">
                <label for="practice-name-input">What is your practice name?</label>
                <input type="text" :class="nameInputClass" name="practice-name-input" v-model="practiceName">
            </div>
        </div>
        <button class="submit-button grow-medium" @click="onSubmitClick">Let's Go</button>
        <h3 class="error-msg" v-if="hasNameError && userTriedToClick">Please fill out all fields before continuing</h3>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { InputToInteger } from '../utils/functions';
export default {
    
    data() {
        return {
            //All onboarding data below
            practiceName: "",
            userTriedToClick: false,
        };
    },
    methods: {
        ...mapActions(['updatePracticeName', 'updatePracticeEmail', 'updatePracticeZip']),
        onSubmitClick() {
            this.userTriedToClick = true;
            //Verify valid input in practiceName, practiceZip
            if(this.verifyData()) {
                this.updatePracticeName(this.practiceName);
                this.$emit('emitDataPageOne');
            }
            else {
                //console.log("You lose☹")
            }
        },
        verifyData() {
            //console.log("Entering verifyData: name = ", this.practiceName, ", email = ", this.practiceEmail, ", zip = ", this.practiceZip)
            return !this.hasNameError
        },
    },
    computed: {
            hasNameError() {
                if(this.practiceName == "") {
                    //console.log("Has name error");
                    return true;
                }
                return false;
            },
            nameInputClass() {
                return {
                    'data-input': true,
                    'hover-grow': true,
                    'data-input-error': this.hasNameError && this.userTriedToClick,
                }
            },
        },
        
  };
</script>

<style scoped>

#window-1 {
    width: 50%;
    height: 80%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 2em;
}

h1 {
    font-size: 2.5em;
    margin: 0;
    margin-top: 0.75em;
    color: var(--color-bright-blue);
}

#input-container {
    background-color: var(--color-light-gray);
    height: 60%;
    width: 75%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    margin-bottom: 2em;
}

.input-wrapper {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 5px;
}

.input-wrapper label {
    display: block;
    margin-bottom: 5px;
    font-size: 1.5em;
}

.data-input {
    width: 75%;
    padding: 8px;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
}

.data-input-error {
    border: 2px solid var(--color-light-red) !important;
}

.data-input:hover {
    outline: none;
    border: 2px solid var(--color-light-blue)
}

.data-input:focus{
    outline: none;
}

.submit-button {
   width: 30%;
    background-color: white;
    height: 40px;
    border-radius: 20px;
    font-size: 1.6em;
    color:var(--color-dark-blue);
    border: 3px solid var(--color-light-blue);
    cursor: pointer;
}

.error-msg {
    margin: 0;
    margin-bottom: 1em;
    color: var(--color-medium-red)
}
</style>