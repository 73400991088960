<template>
    <div class="button">
        <button class="save-btn hover-grow" onmouseup="this.blur();" @click="SaveChanges">{{ btnText }}</button>
    </div>
</template>

<script>
import { UpdatePlanDataInDB } from '../utils/functions';
export default {
    methods: {
        async SaveChanges() {
            this.btnText = "Saving..."
            //console.log("Emitting from SaveChangesButton")
            //await UpdatePlanDataInDB(this.$store.state.id, this.$store.state);
            this.$emit('btnPressed');
        }
    },
    data() {
        return {
            btnText: "Return to Overview"
        }
    }
}
</script>

<style scoped>

</style>