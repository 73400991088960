<template>
    <div id="footer">
      <div id="prev-step" class="side-div hover-grow" @click="onPrevClick">
        <h1 class="right-text no-select" v-if="leftText !== ''"><i class="pi pi-chevron-left chevron"></i></h1>
        <h2 class="right-text no-select">{{ leftText }}</h2>
      </div>
      <div id="body" class="centered">
        <FooterCircle :number="1" :background-color="getCircleColor(1)"/>
        <h2 class="footer-text no-select">Set Pricing</h2>
        <div class="line"></div>
        <FooterCircle :number="2" :background-color="getCircleColor(2)"/>
        <h2 class="footer-text no-select">Discounts</h2>
        <div class="line"></div>
        <FooterCircle :number="3" :background-color="getCircleColor(3)"/>
        <h2 class="footer-text no-select">Add Ons</h2>
      </div>
      <div id="next-step" class="side-div hover-grow" @click="onNextClick">
        <h2 class="right-text no-select">{{ rightText }}</h2>
        <h1 class="right-text no-select" v-if="rightText !== ''"><i class="pi pi-chevron-right chevron"></i></h1>
      </div>
    </div>
</template>

<script>
    import FooterCircle from './FooterCircle.vue'
    export default {
        props: {
          rightText: String,
          leftText: String,
          blueCircle: Number,
        },
        components: {
            FooterCircle
        },
        methods: {
            onNextClick() {
                this.$emit('NextPage')
            },
            onPrevClick() {
                this.$emit('PrevPage')
            },
            getCircleColor(circleNumber) { 
              return this.blueCircle === circleNumber ? 'blue' : 'gray'
            }
        }
    }
</script>
  
  <style>
  #footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--footer-height);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .line {
    border-top: 1px solid black;
    width: 7em;
    height: 1px;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  
  #body {
    display: flex;
    flex-direction: row;
  }

  #body h2 {
    color: black;
  }
  
  .centered {
    align-items: center;
  }

  .footer-text {
    margin: 0; /* Remove default margin */
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1; /* Expand to take available space */
  }

  #next-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  #prev-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

#next-step h2 {
    margin: 0;
    margin-right: 0.5em;
    text-align: right;
}

#next-step h1 {
    margin-right: 1em;
    font-size: 3em;
}

#prev-step h2 {
    margin: 0;
    margin-left: 0.5em;
}

#prev-step h1 {
    margin-left: 1em;
    font-size: 3em;
}

.side-div {
    flex: 1;
}

.right-text {
    color: var(--color-bright-blue)
}

.chevron {
  font-size: 0.5em;
  margin-bottom: 0.9em;
}
  </style>
  