<template>
    <div id="window-1">
        <div class="top-text">
            <h1>Welcome to Dental Menu!</h1>
            <h3>Please fill out this short questionnaire- it'll help us build out your dental plans on our onboarding call.</h3>
        </div>
        <div class="input-wrapper">
            <label for="practice-name-input">Practice Name</label>
            <input type="text" :class="nameInputClass" name="practice-name-input" v-model="practiceName">
        </div>
        <div class="input-wrapper">
            <p class="input-label no-select">Is yours an exclusively pediatric practice?</p>
            <!--Add radio button-->
            <div>
                <input type="radio" id="option1" value="1" v-model="selectedOption">
                <label for="option1">Yes</label>
            </div>
            <div>
                <input type="radio" id="option2" value="2" v-model="selectedOption">
                <label for="option2">No</label>
            </div>
        </div>

        <button class="submit-button grow-medium" @click="onSubmitClick">Let's Go</button>
        <h3 class="error-msg" v-if="hasNameError && userTriedToClick">Please fill out all fields before continuing</h3>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    
    data() {
        return {
            //All onboarding data below
            practiceName: "",
            userTriedToClick: false,
            selectedOption: "2",
            store: this.$store.state,
        };
    },
    methods: {
        ...mapActions(['updatePracticeName', 'convertToPediatric']),
        onSubmitClick() {
            this.userTriedToClick = true;
            //Verify valid input in practiceName, practiceZip
            
            if(this.verifyData()) {
                if(this.selectedOption === "1") {
                    this.convertToPediatric();
                }
                this.updatePracticeName(this.practiceName);
                this.$emit('emitSwap', { shouldGoForward: true });
            }
            else {
                //console.log("You lose☹")
            }
        },
        verifyData() {
            //console.log("Entering verifyData: name = ", this.practiceName, ", email = ", this.practiceEmail, ", zip = ", this.practiceZip)
            return !this.hasNameError
        },
    },
    computed: {
            hasNameError() {
                if(this.practiceName == "") {
                    //console.log("Has name error");
                    return true;
                }
                return false;
            },
            nameInputClass() {
                return {
                    'data-input': true,
                    'hover-grow': true,
                    'data-input-error': this.hasNameError && this.userTriedToClick,
                }
            },

        },
        
  };
</script>

<style scoped>

#window-1 {
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 2px solid var(--color-dark-blue);
    border-radius: 20px;
    justify-content: space-around;
}

.top-text {
    color: var(--color-dark-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 2.5em;
    margin: 0;
    margin-top: 0.75em;
}

h3 {
    width: 75%;
    text-align: center;
    font-weight: 400;
    color: var(--font-color-teal);
}

.input-wrapper {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 5px;
}

.input-wrapper, .input-label label {
    display: block;
    margin-bottom: 5px;
    font-size: 1.5em;
    margin-top: 0;
}

p {
    margin-top: 0;
}

.data-input {
    width: 75%;
    padding: 8px;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
}

.data-input-error {
    border: 2px solid var(--color-light-red) !important;
}

.data-input:hover {
    outline: none;
    border: 2px solid var(--color-light-blue)
}

.data-input:focus{
    outline: none;
}

.error-msg {
    margin: 0;
    margin-bottom: 1em;
    color: var(--color-medium-red)
}

.checkbox-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 29px;
    height: 29px;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.5em;
}
</style>