<template>
    <div id="full-container">
        <h1 id="top-text">UCR Information</h1>
        <div id="window">
            <div class="left-side">
                <div id="top-row">
                    <h2 class="top-description">Enter your benefits' UCR fees here.</h2>
                    <button class="notes-btn grow-medium" @click="ShowNotes()">+ Add Notes</button>
                </div>
                <div class="table-container">
                    <div class="header-row">
                        <div class="first-row header">Essential Benefits</div>
                        <div class="second-row header" style="padding-left: 1%;">Code</div>
                        <div class="third-row header" style="padding-left: 1%;">UCR</div>
                    </div>
                    <table>
                        <tbody>
                            <tr v-for="benefit in essentialBenefits" :key="benefit.id">
                            <td class="first-row">{{ benefit.name }}</td>
                            <td class="second-row">{{ benefit.code }}</td>
                            <td class="third-row">
                                <input v-model="benefit.UCR"
                                    class="code-input grow-medium"
                                    :class="{'input-incorrect': ((benefit.UCR === 0 || benefit.UCR === null) && userTriedToAdvance && essentialCodes.includes(benefit.code))}"
                                    type="text"
                                    placeholder="UCR"
                                    @input="validatePrice(benefit, $event.target.value)"/>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="header-row">
                        <div class="first-row header">Other Benefits</div>
                    </div>
                    <table>
                        <tbody>
                            <tr v-for="benefit in nonEssentialBenefits" :key="benefit.id">
                            <td class="first-row">{{ benefit.name }}</td>
                            <td class="second-row">{{ benefit.code }}</td>
                            <td class="third-row">
                                <input v-model="benefit.UCR"
                                    class="code-input grow-medium"
                                    :class="{'input-incorrect': ((benefit.UCR === 0 || benefit.UCR === null) && userTriedToAdvance && essentialCodes.includes(benefit.code))}"
                                    type="text"
                                    placeholder="UCR"
                                    @input="validatePrice(benefit, $event.target.value)"/>
                            </td>
                            <td class="third-row">
                                <i class="pi pi-trash delete grow-medium delete-icon" @click="RemoveBenefit(benefit)"></i>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style="color: var(--color-medium-red)" v-if="userTriedToAdvance && !benefitUCRsFilled">Please fill out all essential UCRs to continue</p>
                
            </div>
            <div class="btn-container">
                <button class="add-btn hover-grow" @click="addBenefitClick" v-if="this.mode !== 'intake'">+ Add Benefit</button>
                <button class="add-btn hover-grow" v-else @click="SubmitIntake">{{ btnText }}</button>
            </div>
            <div class="message" v-if="btnText === 'Submitted!'">
                {{ messageText }}
            </div>
        </div>

        <SaveChangesButton v-if="this.mode === 'view'" @btnPressed="GoToOverview()"></SaveChangesButton>

        <div class="modal-overlay" v-if="showModal" @click.self="removeModal">
            <PageTwoAddBenefitModal @click.stop @exitModal="removeModal">
                
            </PageTwoAddBenefitModal>
        </div>
        <div class="modal-overlay" v-if="showNotes" @click.self="RemoveNotes">
            <NotesModal @click.stop @exitModal="RemoveNotes">
                
            </NotesModal>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { InputToInteger, GoToComponent, SendPlanInformation } from '../utils/functions.js'
//import PlusMinusButton from './PlusMinusButton.vue';
import SaveChangesButton from './SaveChangesButton.vue';
import PageTwoAddBenefitModal from './modals/PageTwoAddBenefitModal.vue';
import NotesModal from './modals/NotesModal.vue';
export default {
    props: {
        parentUserTriedToAdvance: Boolean,
        mode: String
    },
    components: {
        //PlusMinusButton,
        SaveChangesButton,
        PageTwoAddBenefitModal,
        NotesModal,
    },
    data() {
        return {
            showModal: false,
            showNotes: false,
            btnText: "Submit",
            messageText: "Survey submitted!",
            userTriedToAdvance: false,
        }
    },
    methods: {
        ...mapActions(['updateRewardsPoints',
        'updateEnrollmentFee',
        'removeBenefitStore',
        'updateBenefitByIDStore',
        'setIsFreshIntake']),
        UpdateEnrollmentFee(value){
            let result = 0;
            if(value === 1) {
                result = this.enrollmentFee + 1
            }
            else if (value === -1 && this.enrollmentFee === 0) {
                return
            }
            else {
                result = this.enrollmentFee - 1;
            }
            this.updateEnrollmentFee(result);
        },
        UpdateRewardsPointsPerVisit(value){
            let result = 0;
            if(value === 1) {
                result = this.rewardsPointsPerVisit + 5
            }
            else if (value === -1 && this.rewardsPointsPerVisit === 0) {
                return
            }
            else {
                result = this.rewardsPointsPerVisit - 5;
            }
            this.updateRewardsPoints(result);
        },
        validatePrice(benefit, val) {
            let result = InputToInteger(val);
            benefit.UCR = result;
            this.updateBenefitByIDStore(benefit);
        },
        GoToOverview() {
            GoToComponent(this, 9);
        },
        RemoveBenefit(benefit) {
            this.removeBenefitStore(benefit);
        },
        addBenefitClick() {
            this.showModal = true;
        },
        removeModal() {
            this.showModal = false;
        },
        ShowNotes() {
            this.showNotes = true;
        },
        RemoveNotes() {
            this.showNotes = false;
        },
        async SubmitIntake() {
            this.setIsFreshIntake(true);
            if(!this.benefitUCRsFilled) {
                this.userTriedToAdvance = true;
                return;
            }
            if(this.btnText === "Submitted!" || this.btnText === "Submitting...") {
                return;
            }
            //set isFreshIntake here
            this.btnText = "Submitting..."
            const result = await SendPlanInformation(this.$store.state, true)
            if(result === 404) {
                return;
            }
            this.btnText = "Submitted!"
        }
    },
    computed: {
        benefits() {
            let benefits = this.$store.state.benefits;
            console.log("Benefits = ", benefits);
            if(this.$store.state.isPediatric) {
                benefits.splice(2,1)
            }
            let nonEssentialBenefits = []
            let essentialBenefits = []
            benefits.forEach((benefit) => {
                if(this.essentialCodes.includes(benefit.code)) {
                    essentialBenefits.push(benefit);
                }
                else {
                    nonEssentialBenefits.push(benefit);
                }
            })
            essentialBenefits.sort((a, b) => {
                const codeA = parseInt(a.code.substring(1), 10);
                const codeB = parseInt(b.code.substring(1), 10);
                return codeA - codeB;
            });
            nonEssentialBenefits.sort((a, b) => {
                const codeA = parseInt(a.code.substring(1), 10);
                const codeB = parseInt(b.code.substring(1), 10);
                return codeA - codeB;
            });
            benefits = [...essentialBenefits, ...nonEssentialBenefits];
            return benefits;
        },
        essentialBenefits() {
            let essentialBenefits = [];
            this.$store.state.benefits.forEach((benefit) => {
                if(this.essentialCodes.includes(benefit.code)) {
                    essentialBenefits.push(benefit);
                }
            })
            essentialBenefits.sort((a, b) => {
                const codeA = parseInt(a.code.substring(1), 10);
                const codeB = parseInt(b.code.substring(1), 10);
                return codeA - codeB;
            });
            return essentialBenefits;
        },
        nonEssentialBenefits() {
            let nonEssentialBenefits = [];
            this.$store.state.benefits.forEach((benefit) => {
                if(!this.essentialCodes.includes(benefit.code)) {
                    nonEssentialBenefits.push(benefit);
                }
            })
            nonEssentialBenefits.sort((a, b) => {
                const codeA = parseInt(a.code.substring(1), 10);
                const codeB = parseInt(b.code.substring(1), 10);
                return codeA - codeB;
            });
            return nonEssentialBenefits;
        },
        addOnBenefits() {
            return this.$store.state.addOnBenefits;
        },
        enrollmentFee: {
            get() {
                return this.$store.state.enrollmentFeeFirstVisit;
            },
            set(val) {
                this.updateEnrollmentFee(val)
            }
        },
        rewardsPointsPerVisit: {
            get() {
                return this.$store.state.rewardsPointsPerVisit
            },
            set(val) {
                this.updateRewardsPoints(val)
            }
        },
        benefitUCRsFilled() {
            return this.$store.getters.benefitUCRsAllFilled();
        },
        essentialCodes() {
            let codes = this.$store.state.essentialCodes;
            if(this.$store.state.isPediatric) {
                codes.splice(4,1);
            }
            return codes;
        }
    },
    watch: {
    parentUserTriedToAdvance(newVal) {
        this.userTriedToAdvance = newVal;
    }
},
}
</script>

<style scoped>
#full-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
}

#top-text {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
    color: var(--font-color-teal)
}

#window {
    width: 85%;
    height: 100%;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
}

.left-side {
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: center;
}

.table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin: 20px;
    overflow-x: auto;
    margin-top: 0;
}

.header-row {
    display: flex;
    margin-left: 1%;
    font-weight: 700;
    align-items: center;
    font-size: 1.1em;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid var(--color-dark-blue);
}

th, td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: white;
}

tr:nth-child(even) {
    background-color: var(--color-light-gray)
}

input[type="text"] {
  width: 100%;
  padding: 6px 10px;
  margin: 4px 0;
  box-sizing: border-box;
}

.first-row {
    width: 50%;
    padding-top: 0;
    padding-bottom: 0;
}

.header {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.second-row {
    width: 25%;
    padding-top: 0;
    padding-bottom: 0;
}

.third-row {
    width: 25%;
    padding-top: 0;
    padding-bottom: 0;
}

.fourth-row {
    width: 5%;
}


.code-input {
    outline: 0;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 7px;
}

.code-input:hover {
    border: 2px solid var(--color-light-blue)
}

.right-side {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#top-row h2 {
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.3em;
}

.rewards {
    width: 90%;
    border-radius: 20px;
    background-color: var(--color-light-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}

.page-two-item h3 {
    display: flex;
    flex: 3;
}

.bubble-things {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: space-around;
}

.input-container {
    margin-left: 0.5em;
    position: relative;
}

.bubble {
    background-color: white;
    color: var(--color-bright-blue);
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 10px;
    font-size: 2em;
    font-weight: 900;
    width: 1.75em;
    max-width: 2em;
    height: 1.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bubble:focus {
    border: 2px solid var(--color-light-blue);
    outline: none;
}

.dollar-sign {
    position: absolute;
    bottom: 15%;
    left: 6%;
    color: var(--color-bright-blue);
    font-size: 1.2em;
}


.plus-minus-buttons {
    margin-left: 0.6em;
}

.page-two-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 0.5em;
}


.rewards-points, .reimbursement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

h4 {
    margin: 0;
    margin-bottom: 0.5em;
}

.delete-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line-break {
    border-left: 0;
    border-right: 0;
}

.btn-container {
    display: flex;
    justify-content: center;
    width: 97%;
    margin-bottom: 1em;
}

.add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 4em;
    width: 40%;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.5em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
    max-height: 55px;
    margin: 0.5em;
}

.message {
    display: flex;
    justify-content: center;
}

.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Changed from absolute to fixed */
    top: 0%;
    left: 0%;
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    z-index: 98;
    background-color: rgba(0,0,0,0.2); /* semi-transparent white */
}

</style>