<template>
    <div class="full-container">
        <h1 class="top-text">Rewards</h1>
        <div class="window">
            <div id="top-row">
                <h2 class="top-description">Set up your Rewards program & Enrollment Fee.</h2>
                <button class="notes-btn grow-medium" @click="ShowNotes()">+ Add Notes</button>
            </div>
            <div class="body">
                <div class="rewards-column-alt">
                    <div class="rewards">
                        <div class="left-row">
                            <h3 class="left-text">Rewards Points Per Visit:</h3>
                            <div class="bubble-things">
                                <div class="plus-minus-buttons">
                                    <PlusMinusButton :char="'+'" @plusMinusClick="UpdateRewardsPointsPerVisit"/>
                                    <PlusMinusButton :char="'-'" @plusMinusClick="UpdateRewardsPointsPerVisit"/>
                                </div>
                                <div class="input-container">
                                    <input class="bubble grow-medium" type="text" v-model="rewardsPointsPerVisit" @input="validateRewardsPoints($event.target.value)"/> 
                                </div>
                            </div>
                        </div>
                        <!-- <div class="left-row">
                            <h3 class="left-text">Rewards Reimbursement Percentage:</h3>
                            <div class="bubble-things">
                                <div class="plus-minus-buttons">
                                    <PlusMinusButton :char="'+'" @plusMinusClick="UpdateReimbursementPercentageLocal"/>
                                    <PlusMinusButton :char="'-'" @plusMinusClick="UpdateReimbursementPercentageLocal"/>
                                </div>
                                <div class="input-container">
                                    <input class="bubble hover-grow" type="text" v-model="reimbursementPercentage">
                                    <span class="percent-sign">%</span> 
                                </div>
                            </div>
                        </div> -->
                        <div class="left-row">
                            <h3 class="left-text">Include points in monthly fee:</h3>
                            <div class="checkbox-container">
                                <div class="checkbox grow-medium" @click="toggleChecked($event)">
                                    <div class="checkbox-fill" v-if="includePointsInPlanPrice">
                                        <p class="checkbox-text no-select">✓</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rewards">
                        <div class="left-row">
                        <h3 class="left-text">Enrollment Fee:</h3>
                        <div class="bubble-things">
                            <div class="plus-minus-buttons">
                                <PlusMinusButton :char="'+'" @plusMinusClick="UpdateEnrollmentFee"/>
                                <PlusMinusButton :char="'-'" @plusMinusClick="UpdateEnrollmentFee"/>
                            </div>
                            <div class="input-container">
                                <span class="dollar-sign">$</span>
                                <input class="bubble hover-grow" type="text" v-model="enrollmentFee" @input="validateEnrollmentFee($event.target.value)"/>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div class="rewards-column" style="padding-bottom: 0.5em;">
                    <div class="top-row">
                        <h2 class="top-text">Plan</h2>
                        <h2 class="top-text">Price</h2>
                    </div>
                    <div class="plan-row" v-for="plan in plans" :key="plan.id">
                        <div class="plan-row-side"><p style="font-size: 1.3em; color: var(--color-dark-blue)">{{ GetPlanTypeString(plan.planType) }}</p>every {{ plan.frequency }} months</div>
                        <div class="plan-row-side" style="font-size: 1.5em;" :style="{ color: plan.monthlyPriceAltered ? 'var(--color-bright-blue)' : 'black' }">${{ plan.price }}</div>
                    </div>
                    <div class="right-footer" v-if="existManualPrices">Prices in <span style="color: var(--color-bright-blue)">blue</span> set manually<br/>Manual prices are not changed by rewards</div>
                </div>
            </div>
            
        </div>
        <SaveChangesButton v-if="this.mode === 'view'" @btnPressed="GoToOverview()"></SaveChangesButton>
        <div class="modal-overlay" v-if="showNotes" @click.self="RemoveNotes">
            <NotesModal @click.stop @exitModal="RemoveNotes">
                
            </NotesModal>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GoToComponent, CalculateComputedPlanPrice, InputToInteger } from '../utils/functions';
import PlusMinusButton from './PlusMinusButton.vue';
import SaveChangesButton from './SaveChangesButton.vue';
import NotesModal from './modals/NotesModal.vue';
export default {
    components: {
        PlusMinusButton,
        SaveChangesButton,
        NotesModal,
    },
    props: {
        mode: String,
    },
    data() {
        return {
            showNotes: false,
        }
    },
    methods: {
        toggleIncludePoints() {
            this.includePointsInPlanPrice = !this.includePointsInPlanPrice()
        },
        ...mapActions(['updateRewardsPoints',
        'updateEnrollmentFee',
        'updateBenefitByIdStore',
        'updateReimbursementPercentage',
        'togglePointsIncludedInPrice',
        'updatePlan']),
        UpdateEnrollmentFee(value){
            let result = 0;
            if(value === 1) {
                result = this.enrollmentFee + 1
            }
            else if (value === -1 && this.enrollmentFee === 0) {
                return
            }
            else {
                result = this.enrollmentFee - 1;
            }
            this.updateEnrollmentFee(result);
        },
        UpdateRewardsPointsPerVisit(value){
            let result = 0;
            if(value === 1) {
                result = this.rewardsPointsPerVisit + 5
            }
            else if (value === -1 && this.rewardsPointsPerVisit === 0) {
                return
            }
            else {
                result = this.rewardsPointsPerVisit - 5;
            }
            this.updateRewardsPoints(result);
            this.updatePlans();
        },
        UpdateReimbursementPercentageLocal(value) {
            let result = 0;
            if(value === 1) {
                result = this.reimbursementPercentage + 5
                if(result > 100) {
                    result = 100;
                }
            }
            if(value === -1) {
                result = this.reimbursementPercentage - 5
                if(result < 0) {
                    result = 0;
                }
            }
            //console.log("Reimbursement Percentage = ", result);
            this.updateReimbursementPercentage(result);
            this.updatePlans();
        },
        toggleChecked() {
            this.togglePointsIncludedInPrice(!this.includePointsInPlanPrice)
            this.updatePlans();
        },
        updatePlans() {
            this.plans.forEach((plan) => {
                plan.price = CalculateComputedPlanPrice(this.$store, plan);
                this.updatePlan(plan);
            })
        },
        GoToOverview() {
            GoToComponent(this, 9);
        },
        ShowNotes() {
            this.showNotes = true;
        },
        RemoveNotes() {
            this.showNotes = false;
        },
        validateEnrollmentFee(val) {
            let result = InputToInteger(val);
            this.enrollmentFee = result;
        },
        validateRewardsPoints(val) {
            let result = InputToInteger(val);
            this.rewardsPointsPerVisit = result;
        },
        GetPlanTypeString(type) {
            return this.$store.getters.getPlanTypeFromInt(type);
        }
    },
    computed: {
        includePointsInPlanPrice: {
            get() {
                return this.$store.state.includePointsInPlanPrice
            },
            set(value) {
                this.togglePointsIncludedInPrice(!this.includePointsInPlanPrice)
            }
        },
        enrollmentFee: {
            get() {
                return this.$store.state.enrollmentFeeFirstVisit;
            },
            set(val) {
                this.updateEnrollmentFee(val)
            }
        },
        rewardsPointsPerVisit: {
            get() {
                return this.$store.state.rewardsPointsPerVisit
            },
            set(val) {
                this.updateRewardsPoints(val)
            }
        },
        reimbursementPercentage: {
            get() {
                return this.$store.state.reimbursementPercentage
            },
            set(val) {
                this.UpdateReimbursementPercentageLocal(val)
            }
        },
        plans() {
            return this.$store.getters.getActivePlans();
        },
        existManualPrices() {
            return this.plans.some(plan => plan.monthlyPriceAltered);
        },
        planTypeString() {
            return this.$store.getters.getPlanTypeFromInt(this.planType);
        }
    }
}
</script>

<style scoped>
.top-text {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
    color: var(--font-color-teal);
}

.full-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:0;
}

.window {
    width: 95%;
    max-height: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 20px;
}

#top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#top-row h2 {
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.3em;
}

.body {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1.5%;
}

.rewards-column {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 2px solid var(--color-medium-gray);
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
}

.rewards-column-alt {
    margin-left: 5%;
    margin-right: 5%;
    display: grid; /* Use grid instead of flex */
    grid-template-rows: auto 1fr; /* Define rows: auto for the content size, 1fr takes remaining space */
    flex: 1;
    align-items: start; /* Align items to the start of the grid area */
}

.rewards {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 2px solid var(--color-medium-gray);
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 10px;
    
}

.bubble-things {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: space-between;
}

.input-container {
    margin-left: 0.5em;
    position: relative;
    display: flex;
    align-items: center;
    flex: 2;
}

.bubble {
    background-color: white;
    color: var(--color-bright-blue);
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 10px;
    font-size: 2em;
    font-weight: 900;
    width: 2.1em;
    max-width: 3em;
    height: 1.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0.7em;
    margin-left: 20px;
}

.bubble:focus {
    border: 2px solid var(--color-light-blue);
    outline: none;
}

.dollar-sign {
    position: absolute;
    color: var(--color-bright-blue);
    font-size: 1.5em;
    left: 5%;
}

.percent-sign {
    position: absolute;
    color: var(--color-bright-blue);
    font-size: 1.5em;
    font-weight: 600;
    right: -1%;
}


.plus-minus-buttons {
    margin-left: 1em;
    flex: 2;
    justify-content: space-between;
}

.left-row {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.left-text {
    display: flex;
    flex: 4;
}

.checkbox-container {
    position:relative;
    display: flex;
    flex: 2;
    justify-content: center;
}

.checkbox {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.5em;
    left: 2px;
    top: -3px;
}

.top-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.plan-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.plan-row-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.25em;
}

.right-footer {
    margin-top: 0.5em;
    text-align: center;
}

p {
    margin: 0;
}
</style>