<template>
    <div class="full-page">
        <div class="window loading-screen" v-if="!callReturned">
            <span class="top-text loading-text">Plan Loading....</span>
            <div class="lds-dual-ring"></div>
        </div>
        <div class="window" v-else-if="gotLoad404">
            <span class="title" style="font-size: 2em; margin-bottom: 1em;">Plan not found</span>
            <span class="subtitle" style="text-align: center">Couldn't find a plan with code {{ planKey }} in database. If you're sure that code is correct, Carter can help get everything sorted out!</span>
        </div>
        <div class="window" v-else-if="gotLoad500">
            <span class="title" style="font-size: 2em; margin-bottom: 1em;">Server Error</span>
            <span class="subtitle" style="text-align: center;">The server encountered an error trying to retrieve that plan. Try refreshing the page- if that doesn't work, please tell Carter about the error & include the plan code in your message! <a href="mailto:carter@dentalmenu.com" style="color:var(--color-bright-blue)">carter@dentalmenu.com</a></span>
         </div>
        <div class="window" v-else>
            <span class="top-text">Plan Admin</span>
            <span class="subtitle">for {{ practiceName }}</span>
            <div class="two-column-row">
                <span class="attribute-name">Monthly Fee:</span>
                <span style="margin-top: 1px; margin-right: 5px; font-size: 1.2em; color: var(--color-bright-blue)">$</span><input type="text" v-model="monthlyFeeString" class="input-class hover-grow" @input="ValidateMonthlyFee()">
            </div>
            
                <button class="submit-btn hover-grow" @click="SubmitPlan()">
                    Save
                </button>
                <div class="submit submit-error" v-if="gotSubmit500">
                    There was an error while saving this plan. No changes were made to the database. If this issue persists, get in touch with Carter!
                </div>
                <div class="submit submit-error" v-if="gotSubmit404">
                    Unable to locate a plan with this key. If you see this error, get in touch with Carter!
                </div>
                <div class="submit submit-error" v-if="gotSubmit400">
                    There's bad data somewhere in this practice's plans. Here's the error message returned by the server: <br/><br/>
                    {{ errorMessage }}<br/><br/>
                </div>
                <div class="submit submit-success" v-if="gotSubmit200">
                    Changes saved!
                </div>
        </div>
    </div>
</template>

<script>
import { GetPlanFromDB, UpdatePlanDataInDB, CalculateComputedPlanPrice } from '../Onboarding/utils/functions';

import { mapActions } from 'vuex';
export default {
    props: {
        planKey: String,
    },
    data() {
        return {
            practiceName: "",
            extraData: {
                monthlyFee: 2,
            },            
            callReturned: false,
            gotLoad404: false,
            gotLoad500: false,
            gotSubmit404: false,
            gotSubmit500: false,
            gotSubmit200: false,
            gotSubmit400: false,
            errorMessage: "",
            monthlyFeeString: "",
            plan: null,
            planID: 0
        }
    },
    computed: {
        store() {
            return this.$store.state;
        }
    },
    methods: {
        ...mapActions(['updatePlan', 'setEverything', 'setDMFee']),
        ValidateMonthlyFee() {
            // Remove any characters that are not digits or a decimal point
            let max_length = 4
            if(this.monthlyFeeString.length > max_length) {
                this.monthlyFeeString = this.monthlyFeeString.slice(0, max_length);
            }
            this.monthlyFeeString = this.monthlyFeeString.replace(/[^0-9.]/g, '');
            let num = parseFloat(this.monthlyFeeString);
            this.extraData.monthlyFee = num;
            this.plan.dentalMenuFee = num;
            this.setDMFee(num);
        },
        async SubmitPlan() {
            this.gotLoad404 = false;
            this.gotLoad500 = false;
            this.gotSubmit404 = false;
            this.gotSubmit500 = false;
            this.gotSubmit200 = false;
            this.gotSubmit400 = false;
            let response = ""

            if(this.plan.includeDMFee) {
                let plans = this.plan.plans;
                plans.forEach((plan) => {
                    if(plan.monthlyPriceAltered) {
                        return;
                    }
                    plan.price = CalculateComputedPlanPrice(this.$store, plan);
                    if(!plan.monthlyPriceAltered && !plan.annualPriceAltered) {
                        plan.annualPrice = plan.price * 12
                    }
                    this.updatePlan(plan);
                })
            }

            try {
                response = await UpdatePlanDataInDB(this.planID, this.$store.state);
            }
            catch(error) {
                if(error.response.status === 500) {
                    this.gotSubmit500 = true;
                    return;
                }
                else if(error.response.status === 404) {
                    this.gotSubmit404 = true;
                    return;
                }
                else if(error.response.status === 400) {
                    this.gotSubmit400 = true;
                    this.errorMessage = error.response.data;
                    return;
                }
            }
            if(response.status === 200) {
                this.gotSubmit200 = true;
            }
        },
        ToggleClaims() {
            this.extraData.enableClaims = !this.extraData.enableClaims;
        },
        ToggleCash() {
            this.extraData.enableCash = !this.extraData.enableCash;
        },
        ToggleReadyNow() {
            this.extraData.readyNow = !this.extraData.readyNow;
        },
        TogglePediatric() {
            this.extraData.isPediatric = !this.extraData.isPediatric;
        },
        ToggleSuppressRewards() {
            this.extraData.suppressRewards = !this.extraData.suppressRewards;
        },
        ToggleWaiveForAnnual() {
            this.extraData.waiveForAnnual = !this.extraData.waiveForAnnual;
        },
        TogglePortal() {
            this.extraData.patientPortal = !this.extraData.patientPortal;
        },
        async LoadPlan() {
            let returnData = await GetPlanFromDB(this.planKey);
            if (returnData === 404) {
                this.gotLoad404 = true;
                this.callReturned = true;
                return;
            }
            else if (returnData === 500) {
                this.callReturned = true;
                this.gotLoad500 = true;
                return;
            }
            //console.log("SubmitPlan.LoadPlan.returnData = ", returnData);

            let plan = JSON.parse(returnData.plans);
            plan.id = returnData.id;
            this.setEverything(plan);
            this.plan = plan;
            this.planID = returnData.id;
            this.extraData.monthlyFee = plan.dentalMenuFee;
            this.monthlyFeeString = String(this.extraData.monthlyFee);
            this.practiceName = plan.practiceName;
            this.callReturned = true;
        }
    },
    mounted() {
        this.LoadPlan();
    }
}
</script>

<style scoped>
.full-page {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-teal-background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.window {
    background-color: white;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: 3px solid var(--font-color-teal);
    padding: 2em;
}

.top-text {
    color: var(--font-color-teal);
    font-size: 3em;
}

.subtitle {
    color: var(--color-dark-blue);
    font-size: 1.2em;
    margin-bottom: 1.5em;
}

.two-column-row {
    display: flex;
    flex-direction: row;
    width: 75%;
    justify-content: space-between;
    font-size: 1.2em;
    color: var(--color-dark-blue);
    margin: 0.5em;
}

.attribute-name {
    flex: 1;
}

.input-class {
    font-size: 1.05em;
    flex: 1;
    width: 100%;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 5px;
}

.input-class:hover {
    outline: none;
    border: 2px solid var(--color-light-blue);
}

.input-class:focus {
    outline: none;
}

.submit-btn {
    background-color: transparent;
    outline: none;
    border: 3px solid var(--color-medium-light-gray);
    font-size: 1.5em;
    border-radius: 50px;
    cursor: pointer;
    padding: 0.25em 1em;
    color: var(--color-dark-blue);
    font-weight: 900;
    margin-top: 1em;
    text-align: center;
}

.submit {
    margin-top: 1em;
    text-align: center;
    font-weight: 900;
}

.submit-error {
    color: var(--color-medium-red);
}

.submit-success {
    color: var(--color-bright-blue);
    font-size: 2em;
}

.checkbox-container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-hover-border:hover {
    border: none;
}

.checkbox {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.2em;
    left: 2px;
    top: -3px;
}

.submit-btn:hover, .submit-btn:focus {
    border: 3px solid var(--color-dark-blue)
}

.lds-dual-ring {
  display: inline-block;
  width: 40%;
  height: 40%;
  margin-left: 4.5em;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 200px;
  height: 200px;
  margin: 8px;
  border-radius: 50%;
  border: 12px solid transparent;
  border-color: var(--color-dark-blue) transparent var(--color-dark-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>