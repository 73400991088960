<template>
    <div class="page">
        <HeaderBar :inputName="practiceName" />
        <div :class="bodyClass">
            <div class="component-window">
                <component :is="components[currentComponent].name" v-bind="components[currentComponent].props" @ToOverview="GoToOverview()" @LoadPlan="LoadPlan()" @switchComponent="NavigateToComponent" @emitDataPageOne="swapComponent(true)" @planSubmitted="NavigateToComponent({number: 11})" @landingPageChecking="toggleIsCheckingLandingPage()" @landingPageAvailable="setLandingPageAvailable($event)"></component>
            </div>
        </div>
        <div class="footer-spacer"></div>
        <FooterBar v-if="currentComponent > 0 && mode !== 'view'" :right-text="footerRightText" :left-text="footerLeftText" :blue-circle="footerBlueCircle" @NextPage="swapComponent(true)" @PrevPage="swapComponent(false)"/>
    </div>
  </template>
  
  <script>
    import HeaderBar from './components/HeaderBar.vue';
    import FooterBar from './components/FooterBar.vue';
    import PageOne from './components/PageOne.vue';
    import UCRInformation from './components/UCRInfo.vue';
    import PlanPricing from './components/PlanPricing.vue'
    import Rewards from './components/Rewards.vue';
    import Discounts from './components/Discounts.vue';
    import AddOns from './components/AddOns.vue';
    import PlanOverview from './components/PlanOverview.vue';
    import LoadingScreen from'./components/LoadingScreen.vue';
    import ContactPage from './components/ContactInfo.vue'
    import { GetPlanFromDB } from './utils/functions.js'

    import { mapActions } from 'vuex';
  
    export default {
        props: {
            planConfigCode: String,
            inputMode: String,
        },
            components: {
            HeaderBar,
            FooterBar,
            PageOne,
            UCRInformation,
            PlanPricing,
            Rewards,
            Discounts,
            AddOns, 
            PlanOverview,
            LoadingScreen,
            ContactPage,
        },
        data() {
            return {
                currentComponent: 0,
                practiceName: "",
                practiceZip: null,
                footerRightText: "",
                footerLeftText: "",
                footerBlueCircle: 1,
                pageTwoUserTriedToAdvance: false,
                contactPageUserTriedToAdvance: false,
                mode: "",
                getPlanStatus: 0,
                isCheckingLandingPage: false,
                landingPageAvailable: false,
            };
        },
        methods: {
            ...mapActions([
            'setIsFreshIntake',
            'setAdultPlanSet',
            'setChildPlanSet',
            'updatePracticeName',
            'updatePracticeZip',
            'setUCRsDemoStore',
            'setUCRsMainStore',
            'setBenefitPricesAtUCRStore',
            'setBenefitPricesBelowUCRStore',
            'setEverything']),
            swapComponent(shouldGoForward) {
                //console.log("Entering swapComponent")
                if(shouldGoForward) {
                    if(this.currentComponent === 1) {
                        this.pageTwoUserTriedToAdvance = true;
                        if(!this.$store.getters.benefitUCRsAllFilled()) {
                            return;
                        }
                        else {
                            if((this.mode === 'main' || this.mode === 'demo')
                                || 
                                (this.mode === 'build' && this.$store.state.isFreshIntake === true)){
                                    //console.log("Inside of code block")
                                    if (!this.$store.state.ucrsSet) {
                                        //console.log("Setting prices to UCR")
                                        this.setBenefitPricesAtUCRStore();
                                    }
                                    this.setIsFreshIntake(false)
                                }
                            this.setBenefitPricesBelowUCRStore();
                        }
                    }
                    if(this.currentComponent === 2) {
                        //console.log("Setting Adult Plan Set");
                        this.setAdultPlanSet();
                    }
                    if(this.currentComponent === 3) {
                        this.setChildPlanSet();
                    }
                    if(this.currentComponent === 7 && this.mode === 'demo') {
                        this.currentComponent++;
                    }
                    else if(this.currentComponent === 8) {
                        this.contactPageUserTriedToAdvance = true;
                        if(this.isCheckingLandingPage || !this.$store.getters.contactPageFilled() || !this.landingPageAvailable) {
                            return;
                        }
                    }
                    this.currentComponent++;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth' // Optional: for smooth scrolling
                    });
                }
                else {
                    if(this.currentComponent === 9 && this.mode == 'demo') {
                        this.currentComponent--;
                    }
                    this.currentComponent--;
                }
                //console.log("Current component: ", this.currentComponent);
                //BACK BUTTON
                //window.history.pushState({ currentComponent: this.currentComponent }, '', '#' + this.components[this.currentComponent].name);
                this.setFooterData();
            },
            GoToOverview() {
                //console.log("Going to overview")
                this.currentComponent = 8;
            },
            setFooterData() {
                switch(this.currentComponent) {
                    case 1:
                        this.footerRightText = this.$store.getters.getPlanTypeFromInt(0) + " Plans";
                        this.footerLeftText = "";
                        this.footerBlueCircle = 1;
                        break;
                    case 2:
                        //adult pricing
                        this.footerRightText = this.$store.getters.getPlanTypeFromInt(1) + " Plans";
                        this.footerLeftText = "UCR Information";
                        this.footerBlueCircle = 1;
                        break;
                    case 3: // child pricing
                        this.footerRightText = this.$store.getters.getPlanTypeFromInt(2) + " Plans";
                        this.footerLeftText = this.$store.getters.getPlanTypeFromInt(0) + " Plans";
                        this.footerBlueCircle = 1;
                        break;
                    case 4: // perio pricing
                        this.footerRightText = "Rewards";
                        this.footerLeftText = this.$store.getters.getPlanTypeFromInt(1) + " Plans";
                        this.footerBlueCircle = 1;
                        break;
                    case 5: //rewards
                        this.footerLeftText = this.$store.getters.getPlanTypeFromInt(2) + " Plans";
                        this.footerRightText = "Discounts";
                        this.footerBlueCircle = 1;
                        break;
                    case 6: // discounts
                        this.footerRightText = "Add-Ons";
                        this.footerLeftText = "Rewards";
                        this.footerBlueCircle = 2;
                        break;
                    case 7: //add-ons
                        if(this.mode === 'demo') {
                            this.footerRightText = "Plan Overview"
                        }
                        else {
                            this.footerRightText = "Contact Information";
                        }
                        this.footerLeftText = "Discounts";
                        this.footerBlueCircle = 3;
                        break;
                    case 8: //contact info
                        this.footerLeftText = "Add-Ons";
                        this.footerRightText = "Plan Overview";
                        this.footerBlueCircle = 3;
                        break;
                    case 9: //plan overview
                        if(this.mode === 'demo') {
                            this.footerLeftText = "Add-Ons";
                        }
                        else {
                            this.footerLeftText = "Contact Information";
                        }
                        this.footerRightText = "";
                        this.footerBlueCircle = 0;
                }
            },
            unlockExamXRayPrices() {
                this.setAdultPlanSet();
            },
            setPracticeNameLocal() {
                this.updatePracticeName(this.practiceName)
            },
            setPracticeZipLocal() {
                this.updatePracticeZip(this.practiceZip)
            },
            async LoadPlan() {
                //console.log("Entering LoadPlan")
                //send user to a loading screen, execute this logic, then redirect to Plan Overview?
                //console.log("Code = ", this.planConfigCode);
                try {
                    const returnData = await GetPlanFromDB(this.planConfigCode);
                    if(returnData === 404) {
                        this.getPlanStatus = 404;
                        return;
                    }
                    else if(returnData === 500) {
                        this.getPlanStatus = 500;
                        return;
                    }
                    //console.log("Logging returnData now:");
                    //console.log(returnData)
                    let plan = JSON.parse(returnData.plans);
                    //console.log("Parsed plan in Onboarding.vue = ", plan);
                    plan.id = returnData.id;
                    //console.log("Plan ID = ", plan.id);
                    //console.log("Plan = ", plan)
                    this.setEverything(plan);
                    this.practiceName = plan.practiceName;
                    if(this.mode === 'view') {
                        this.swapComponent(false);
                    }
                    else if(this.mode === 'build') {
                        let num = 1;
                        if(this.$store.state.practiceName === "") {
                            num = 0;
                        }
                        this.NavigateToComponent({number: num});
                    }
                }
                catch (error) {
                    console.log("Error = ", error);
                }
            },
            NavigateToComponent(payload) {
                this.currentComponent = payload.number;
                //BACK BUTTON
                //window.history.pushState({ currentComponent: payload.number }, '', '#' + this.components[payload.number].name);
                this.setFooterData();
                window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth' // Optional: for smooth scrolling
                    });
            },
            toggleIsCheckingLandingPage() {
                this.isCheckingLandingPage = !this.isCheckingLandingPage;
            },
            setLandingPageAvailable(data) {
                //console.log("Data.available = ", data.available);
                this.landingPageAvailable = data.available;
            }
            //BACK BUTTON
            // handlePopState(event) {
            //     if (event.state) {
            //         this.currentComponent = event.state.currentComponent;
            //         this.setFooterData();
            //     }
            // },
        },
        
        mounted() {
            this.setFooterData();
            if(this.mode === 'demo') {
                this.setPracticeNameLocal()
            }
            //BACK BUTTON
            //window.history.replaceState({ currentComponent: this.currentComponent }, '', '#' + this.components[this.currentComponent].name);

            //BACK BUTTON
            //console.log("Adding event listener")
            //window.addEventListener('popstate', this.handlePopState.bind(this));
        },
        created() {
            if(this.inputMode === "main") {
                this.mode = 'main';
                this.currentComponent = 0;
                this.setUCRsMainStore();
            }
            else if(this.inputMode === "demo") {
                this.mode = 'demo';
                this.currentComponent = 1;
                this.practiceName = "Demo Dental"
                this.setUCRsDemoStore();
            }
            else if (this.inputMode === "view") {
                this.mode = 'view';
                this.currentComponent = 10;
                this.footerBlueCircle = 0;
            }
            else if(this.inputMode === "build") {
                this.mode = 'build';
                this.currentComponent = 10;
            }
        },
        // beforeDestroy() {
        //      BACK BUTTON
        //     window.removeEventListener('popstate', this.handlePopState);
        // },
        computed: {
            store() {
                return this.$store.state
            },
            bodyClass() {
                return {
                    'body': true,
                    'body-page-one': this.currentComponent === 0
                }
            },
            components() {
                return [
                    {
                        name: "PageOne",
                        props: {},
                    },
                    {
                        name: "UCRInformation",
                        props: {parentUserTriedToAdvance: this.pageTwoUserTriedToAdvance, mode: this.mode}
                    },
                    {
                        name: "PlanPricing",
                        props: {planType: 0, mode: this.mode}
                    },
                    {
                        name: "PlanPricing",
                        props: {planType: 1, mode: this.mode}
                    },
                    {
                        name: "PlanPricing",
                        props: {planType: 2, mode: this.mode}
                    },
                    {
                        name: "Rewards",
                        props: {mode: this.mode}
                    },
                    {
                        name: "Discounts",
                        props: {mode: this.mode}
                    },
                    // {
                    //     name: "TreatmentDiscounts",
                    //     props: {mode: this.mode}
                    // },
                    {
                        name: "AddOns",
                        props: {mode: this.mode}
                    },
                    {
                        name: "ContactPage",
                        props: {userTriedToAdvance: this.contactPageUserTriedToAdvance, mode: this.mode}
                    },
                    {
                        name: "PlanOverview",
                        props: {mode: this.mode}
                    },
                    {
                        name: "LoadingScreen",
                        props: {getPlanStatus: this.getPlanStatus}
                    },
                ]
            }
        }

}
  </script>
  
  <style scoped>
 
  .body {
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    display: flex;
    flex-direction: column;
    background-color: var(--color-teal-background);
  }

  .body-page-one {
    align-items: center;
    justify-content: center;
  }
  
  .component-window {
    height: 90%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-spacer {
    height: var(--footer-height);
    background-color: rgba(226,239,237,255);
  }
  </style>
  