<template>
    <div class="full-modal">
        <div class="btn-exit grow-medium" @click="ExitModal"><i class="pi pi-times"></i></div>
        <h1 class="top-text">Add Notes</h1>
        <p class="description">A place to put down any information not covered in the plan builder</p>
        <textarea v-model="note" class="note-box" @input="adjustTextAreaHeight" ref="noteTextArea"></textarea>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        planType: String
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscape);
        this.$nextTick(() => {
            this.adjustTextAreaHeight(); // Adjust initially in case of pre-filled content
        });
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEscape);
    },
    methods: {
        ...mapActions(['setNote']),
        ExitModal() {
            this.$emit('exitModal');
        },
        handleEscape(event) {
            if(event.key === 'Escape') {
                this.ExitModal();
            }
        },
        adjustTextAreaHeight() {
            const textArea = this.$refs.noteTextArea;
            textArea.style.height = "auto"; // Reset height to recalculate
            textArea.style.height = textArea.scrollHeight + "px"; // Set new height based on content
        },
    },
    computed: {
        note: {
            get() {
                return this.$store.state.note
            },
            set(val) {
                this.setNote(val);
            }
        }
    }
}
</script>

<style scoped>

.full-modal {
    position: relative;
    width: 30%;
    height: 55%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 3px solid var(--color-dark-blue);
    outline: 2px solid white;
    padding: 2%;
}

.btn-exit {
    position: absolute;
    top: 20px;
    right: 25px;
    color: red;
    font-size: 1.75em;
    cursor: pointer;
    font-family: sans-serif
}

.top-text {
    color: var(--font-color-teal);
    margin-top: 0.25em;
}

.description {
    text-align: center;
    width: 70%;
    margin-top: 0;
}

.note-box {
    margin-top: 2em;
    width: 60%;
    outline: none;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 10px;
    resize: none;
    padding: 5px;
    font-family: 'Main';
}

.note-box:hover {
    border: 2px solid var(--color-light-blue);
}

.note-box:focus {
    border: 2px solid var(--color-bright-blue);
}
</style>