<template>
    <div class="full-modal">
        <div class="btn-exit grow-medium" @click="ExitModal"><i class="pi pi-times"></i></div>
        <h1 class="top-text">Save Changes?</h1>
        <textarea v-model="changes" class="note-box" @input="adjustTextAreaHeight" ref="noteTextArea"></textarea>
        <div class="save-btn"
            onmouseup="this.blur();"
            @click="HandleClick()"
            style="padding-right:0.35em; padding-left: 0.35em;">{{btnText}}
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { UpdatePlanDataInDB } from '../../utils/functions'
export default {
    props: {
        mode: String
    },
    data() {
        return {
            changes: "",
            btnText: "Confirm Changes",
            save404: false,
            save500: false,
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscape);
        this.$nextTick(() => {
            this.adjustTextAreaHeight(); // Adjust initially in case of pre-filled content
        });
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEscape);
    },
    methods: {
        ...mapActions(['setNote', 'setUneditedStateToCurrentState']),
        ExitModal() {
            this.$emit('exitModal');
        },
        handleEscape(event) {
            if(event.key === 'Escape') {
                this.ExitModal();
            }
        },
        adjustTextAreaHeight() {
            const textArea = this.$refs.noteTextArea;
            textArea.style.height = "auto"; // Reset height to recalculate
            textArea.style.height = textArea.scrollHeight + "px"; // Set new height based on content
        },
        HandleClick() {
            if(this.mode === 'confirm') {
                this.SaveChanges();
            }
            else {
                this.$emit('revert');
                this.ExitModal();
            }
        },
        async SaveChanges() {
                this.btnText = "Saving..."
                const result = await UpdatePlanDataInDB(this.$store.state.id, this.$store.state, this.changes);
                if(result.status === 404) {
                    this.save404 = true;
                    this.btnText = "Save Changes";
                    return;
                }
                else if(result.status === 500) {
                    this.save500 = true;
                    this.btnText = "Save Changes";
                    return;
                }
                else {
                    this.planLink += result.data;
                    this.showPlanLink = true;
                    this.$emit('btnPressed');
                    this.btnText = "Changes Saved";
                }
                this.setUneditedStateToCurrentState();
            },
        listChanges() {
            const newPlan = this.$store.state
            const oldPlan = newPlan.uneditedState;
            //check 
            //MISC FROM PLANPRICING.VUE
            Object.keys(newPlan.percentBreakage).forEach(key => {
                if(newPlan.percentBreakage[key] !== oldPlan.percentBreakage[key]) {
                    this.changes += `${this.$store.getters.getPlanTypeFromInt(key)} plan breakage changed from ${oldPlan.percentBreakage[key]}% to ${newPlan.percentBreakage[key]}%\n\n`
                }
            });
            if(newPlan.includeDMFee !== oldPlan.includeDMFee) {
                if(newPlan.includeDMFee) {
                    this.changes += `Included Dental Menu fee in plan prices\n\n`;
                }
                else {
                    this.changes += `Removed Dental Menu Fee from plan prices\n\n`
                }
            }
            if(newPlan.includeProcessingFee !== oldPlan.includeProcessingFee) {
                if(newPlan.includeProcessingFee) {
                    this.changes += `Included payment processing fee in plan prices`
                }
                else {
                    this.changes += `Removed payment processing fee from plan prices`
                }
            }
            if(newPlan.rewardsPointsPerVisit !== oldPlan.rewardsPointsPerVisit) {
                this.changes += `Rewards Points per visit changed from ${oldPlan.rewardsPointsPerVisit} to ${newPlan.rewardsPointsPerVisit}\n\n`;
            }
            if(newPlan.reimbursementPerccentage !== oldPlan.reimbursementPerccentage) {
                this.changes += `Reimbursement Percentage changed from ${oldPlan.reimbursementPerccentage} to ${newPlan.reimbursementPerccentage}\n\n`;
            }
            if(newPlan.enrollmentFeeFirstVisit !== oldPlan.enrollmentFeeFirstVisit) {
                this.changes += `Enrollment Fee changed from ${oldPlan.enrollmentFeeFirstVisit} to ${newPlan.enrollmentFeeFirstVisit}\n\n`;
            }
            for(let i = 0; i < newPlan.planTypes.length; i++) {
                if(newPlan.planTypes[i] !== oldPlan.planTypes[i]) {
                    this.changes += `Renamed ${oldPlan.planTypes[i]} plans to ${newPlan.planTypes[i]} plans\n\n`;
                }
            }
            newPlan.benefits.forEach((newBenefit) => {
                let currentID = newBenefit.id;
                //BENEFITS
                // Check if benefit was added this session
                if(!oldPlan.benefits.some(benefit => benefit.id === currentID)) {
                    this.changes += `Added benefit- ${newBenefit.name}\n\n`;
                } else {
                    const oldBenefit = oldPlan.benefits.find(benefit => benefit.id === currentID);
                    newBenefit.planTypes.forEach(planType => {
                        //if a plan type in new benefit doesn't exist in old benefit (benefit was added to a plan type)
                        if(!oldBenefit.planTypes.some(oldPlanType => oldPlanType === planType)) {
                            this.changes += `Added benefit ${newBenefit.name} to ${this.$store.getters.getPlanTypeFromInt(planType)} plans`;
                        }
                        else {
                            //if a plan type is in both old & new, check for frequency & price changes between old & new
                            if(newBenefit.frequencies[planType] !== oldBenefit.frequencies[planType]) {
                                this.changes += `Changed frequency of ${newBenefit.name} from ${oldBenefit.frequencies[planType] === -1 ? "Each Visit" : "Every " + oldBenefit.frequencies[planType] + " months"} to ${newBenefit.frequencies[planType] === -1 ? "Each Visit" : "Every " + newBenefit.frequencies[planType] + " months"} in ${this.$store.getters.getPlanTypeFromInt(planType)} plans\n\n`;
                            }
                            if(newBenefit.prices[planType] !== oldBenefit.prices[planType]) {
                                this.changes += `Changed price of ${newBenefit.name} from $${oldBenefit.prices[planType]} to $${newBenefit.prices[planType]} in ${this.$store.getters.getPlanTypeFromInt(planType)} plans\n\n`;
                            }
                        }
                    })
                    oldBenefit.planTypes.forEach(planType => {
                        //if a plan type in old benefit doesn't exist in new benefit
                        if(!newBenefit.planTypes.some(newPlanType => newPlanType === planType)) {
                            this.changes += `Removed benefit ${newBenefit.name} from ${this.$store.getters.getPlanTypeFromInt(planType)} plans`;
                        }
                    })
                    if(newBenefit.name !== oldBenefit.name) {
                        this.changes += `Renamed benefit ${oldBenefit.name} to ${newBenefit.name}\n\n`;
                    }
                    if(newBenefit.code !== oldBenefit.code) {
                        this.changes += `Changed benefit ${newBenefit.name} code from ${oldBenefit.code} to ${newBenefit.code}\n\n`;
                    }
                    if(newBenefit.UCR !== oldBenefit.UCR) {
                        this.changes += `Changed benefit ${newBenefit.name} UCR from ${oldBenefit.UCR} to ${newBenefit.UCR}\n\n`;
                    }
                }
            });
            //check for any benefits removed during this session
            oldPlan.benefits.forEach((benefit) => {
                let currentID = benefit.id;
                if(!newPlan.benefits.some(benefit => benefit.id === currentID)) {
                    this.changes += `Removed benefit- ${benefit.name}\n\n`;
                }
            });
            //PLANS
            for(let i = 0; i < newPlan.plans.length; i++) {
                let currentNewPlan = newPlan.plans[i];
                let currentOldPlan = oldPlan.plans[i];
                if(currentNewPlan.planName !== currentOldPlan.planName) {
                    this.changes += `Changed ${currentNewPlan.frequency}-month ${this.$store.getters.getPlanTypeFromInt(currentNewPlan.planType)} plan name from "${currentOldPlan.plans[i].planName}" to "${currentNewPlan.plans[i].planName}"\n\n`;
                }
                if(currentNewPlan.isActive !== currentOldPlan.isActive) {
                    if(currentNewPlan.isActive) {
                        this.changes += `Started offering ${this.$store.getters.getPlanTypeFromInt(currentNewPlan.planType)} plans every ${currentNewPlan.frequency} months\n\n`;
                    }
                    else {
                        this.changes += `Stopped offering ${this.$store.getters.getPlanTypeFromInt(currentNewPlan.planType)} plans every ${currentNewPlan.frequency} months\n\n`;
                    }
                }
                if(currentNewPlan.price !== currentOldPlan.price) {
                    this.changes += `Changed ${currentNewPlan.frequency}-month ${this.$store.getters.getPlanTypeFromInt(currentNewPlan.planType)} plan price from $${currentOldPlan.price} to $${currentNewPlan.price}\n\n`;
                }
                if(currentNewPlan.annualPrice !== currentOldPlan.annualPrice && currentNewPlan.annualPriceAltered) {
                    this.changes += `Manually set ${currentNewPlan.frequency}-month ${this.$store.getters.getPlanTypeFromInt(currentNewPlan.planType)} plan annual price to $${currentNewPlan.annualPrice}\n\n`;
                }
            }
            //DISCOUNTS
            for(let i = 0; i < newPlan.monthlyDiscounts.length; i++) {
                const newDiscount = newPlan.monthlyDiscounts[i];
                if(oldPlan.monthlyDiscounts.length <= i) {
                    this.changes += `Created ${newDiscount.title} discount`;
                    continue;
                }
                const oldDiscount = oldPlan.monthlyDiscounts[i];
                if(newDiscount.title !== oldDiscount.title) {
                    this.changes += `Changed discount title from "${oldDiscount.title}" to "${newDiscount.title}"\n\n`;
                }
                if(newDiscount.description !== oldDiscount.description) {
                    this.changes += `Changed ${newDiscount.title} discount description from "${oldDiscount.description}" to "${newDiscount.description}"\n\n`;
                }
                if(newDiscount.dollarsOff !== oldDiscount.dollarsOff) {
                    this.changes += `Changed ${newDiscount.title} discount amount from $${oldDiscount.dollarsOff} off/month to $${newDiscount.dollarsOff} off/month\n\n`;
                }
                if(newDiscount.isActive !== oldDiscount.isActive) {
                    if(newDiscount.isActive) {
                        this.changes += `Added ${newDiscount.title} discount\n\n`;
                    }
                    else {
                        this.changes += `Disabled ${newDiscount.title} discount\n\n`;
                    }
                }
            }
            for(let i = 0; i < newPlan.treatmentDiscounts.length; i++) {
                const newDiscount = newPlan.treatmentDiscounts[i];
                if(oldPlan.treatmentDiscounts.length <= i) {
                    this.changes += `Created ${newDiscount.title} discount`;
                    continue;
                }
                const oldDiscount = oldPlan.treatmentDiscounts[i];
                if(newDiscount.title !== oldDiscount.title) {
                    this.changes += `Changed discount title from "${oldDiscount.title}" to "${newDiscount.title}"\n\n`;
                }
                if(newDiscount.description !== oldDiscount.description) {
                    this.changes += `Changed ${newDiscount.title} discount description from "${oldDiscount.description}" to "${newDiscount.description}"\n\n`;
                }
                if(newDiscount.percentOff !== oldDiscount.percentOff) {
                    this.changes += `Changed ${newDiscount.title} discount amount from ${oldDiscount.percentOff}% off to ${newDiscount.percentOff}% off\n\n`;
                }
                if(newDiscount.isActive !== oldDiscount.isActive) {
                    if(newDiscount.isActive) {
                        this.changes += `Added ${newDiscount.title} discount\n\n`;
                    }
                    else {
                        this.changes += `Removed ${newDiscount.title} discount\n\n`;
                    }
                }
            }
            //ADD-ONS
            for(let i = 0; i < newPlan.addOns.length; i++) {
                let newAddOn = newPlan.addOns[i];
                let oldAddOn = oldPlan.addOns[i];
                if(newAddOn.title !== oldAddOn.title) {
                    this.changes += `Add-On title changed from ${oldAddOn.title} to ${newAddOn.title}\n\n`;
                }
                if(newAddOn.description !== oldAddOn.description) {
                    this.changes += `Changed ${newAddOn.title} Add-On description from ${oldAddOn.description} to ${newAddOn.description}\n\n`;
                }
                if(newAddOn.price !== oldAddOn.price) {
                    this.changes += `Changed ${newAddOn.title} price from ${oldAddOn.price} to ${newAddOn.price}\n\n`;
                }
                if(newAddOn.isActive !== oldAddOn.isActive) {
                    if(newAddOn.isActive) {
                        this.changes += `Enabled ${newAddOn.title} Add-On\n\n`;
                    }
                    else {
                        this.changes += `Disabled ${newAddOn.title} Add-On\n\n`;
                    }
                }
            }
            //CONTACT INFO
            if(newPlan.practiceName !== oldPlan.practiceName) {
                this.changes += `Practice name changed from ${oldPlan.practiceName} to ${newPlan.practiceName}\n\n`;
            }
            if(newPlan.practiceEmail !== oldPlan.practiceEmail) {
                this.changes += `Practice email changed from ${oldPlan.practiceEmail} to ${newPlan.practiceEmail}\n\n`;
            }
            if(newPlan.practiceWebsite !== oldPlan.practiceWebsite) {
                this.changes += `Practice website changed from ${oldPlan.practiceWebsite} to ${newPlan.practiceWebsite}\n\n`;
            }
            if(newPlan.landingPage !== oldPlan.landingPage) {
                this.changes += `Practice landing page changed from ${oldPlan.landingPage} to ${newPlan.landingPage}\n\n`;
            }
            if(newPlan.practiceAddress !== oldPlan.practiceAddress || newPlan.addressLineTwo !== oldPlan.addressLineTwo || newPlan.practiceCity !== oldPlan.practiceCity || newPlan.practiceState !== oldPlan.practiceState || newPlan.practiceZip !== oldPlan.practiceZip) {
                this.changes += `Practice address changed from "${this.formatAddress(oldPlan)}" to "${this.formatAddress(newPlan)}"\n\n`;
            }
            if(newPlan.practiceCity !== oldPlan.practiceCity) {
                this.changes += `City changed from ${oldPlan.landingPage} to ${newPlan.landingPage}\n\n`;
            }
            if(newPlan.practiceZip !== oldPlan.practiceZip) {
                this.changes += `Practice zip code changed from ${oldPlan.practiceZip} to ${newPlan.practiceZip}\n\n`;
            }
            if(newPlan.practicePhone !== oldPlan.practicePhone) {
                this.changes += `Practice phone number changed from ${oldPlan.practicePhone} to ${newPlan.practicePhone}\n\n`
            }
        },
        formatAddress(plan) {
            return plan.practiceAddress + ", " + (plan.addressLineTwo !== "" ? plan.addressLineTwo + " " : "") + plan.practiceCity + " " + plan.practiceState + " " + plan.practiceZip
        }
    },
    computed: {
        note: {
            get() {
                return this.$store.state.note
            },
            set(val) {
                this.setNote(val);
            }
        }
    },
    created() {
        this.listChanges();
        if(this.mode === 'confirm') {
            this.btnText = "Confirm Changes";
        }
        else if(this.mode === 'revert') {
            this.btnText = "Discard Unsaved Changes";
        }
    }
}
</script>

<style scoped>

.full-modal {
    position: relative;
    width: 30%;
    height: 55%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 3px solid var(--color-dark-blue);
    outline: 2px solid white;
    padding: 2%;
}

.btn-exit {
    position: absolute;
    top: 20px;
    right: 25px;
    color: red;
    font-size: 1.75em;
    cursor: pointer;
    font-family: sans-serif
}

.top-text {
    color: var(--font-color-teal);
    margin-top: 0.25em;
}

.description {
    text-align: center;
    width: 70%;
    margin-top: 0;
}

.note-box {
    margin-top: 2em;
    width: 60%;
    outline: none;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 10px;
    resize: none;
    padding: 5px;
    font-family: 'Main';
}

.note-box:hover {
    border: 2px solid var(--color-light-blue);
}

.note-box:focus {
    border: 2px solid var(--color-bright-blue);
}
</style>