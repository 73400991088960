<template>
    <div class="screen">
    <HeaderBar></HeaderBar>
        <h1 class="top-text">Page Not Found</h1>
        <div class="window">
            <p class="text">It looks like this link is no longer working or doesn't exist.<br/> So sorry for the inconvenience!</p>
            <button class="text btn hover-grow" @click="GoToUrl('https://dentalmenuonboarding.com/')">Access the plan builder</button>
            <button class="text btn hover-grow" @click="GoToUrl('https://dentalmenu.com/')">Go to Dental Menu home</button>
        </div>
    </div>
</template>

<script>
import HeaderBar from '@/pages/Onboarding/components/HeaderBar.vue';

export default {
    components: {
        HeaderBar,
    },
    mounted() {
        document.querySelectorAll('.btn').forEach(button => {
            button.addEventListener('mouseup', function() {
                this.blur();
            });
        });
    },
    methods: {
        GoToUrl(page) {
            window.location.href = page;
        }
    }
}
</script>

<style scoped>


.screen {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-text {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
    color: var(--font-color-teal);
}

.window {
    width: 40%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 2%;
}

.text {
    font-size: 1.4em;
    text-align: center;
}

.btn {
    border: 3px solid var(--color-light-blue);
    border-radius: 500px;
    padding: 0.5em 1em;
    cursor: pointer;
    background-color: transparent;
    margin: 0.5em 0;
    color: var(--color-dark-blue);
    font-weight: 600;
}

.btn:hover {
    border: 3px solid var(--color-bright-blue);
}

.btn:focus {
    border: 3px solid var(--color-dark-blue);
}
</style>