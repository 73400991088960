<template>
    <div :class="ButtonClass" @click="OnClick">
        <img class="image" :src="imageSource">
    </div>
</template>

<script>
export default {
    props: {
        char: {
            type: String,
            required: true
        }
    },
    methods: {
        OnClick() {
            this.$emit('plusMinusClick', this.char === '+' ? 1 : -1);
        }
    },
    computed: {
        ButtonClass() {
            return {
                "btn": true,
                "grow-large": true,
            }
        },
        imageSource() {
            return require(this.char === '+' ? '@/assets/plus.png' : '@/assets/minus.png');
        }
    }
}
</script>

<style scoped>
.btn {
    box-sizing: border-box;
    width: var(--plus-minus-button-size);
    height: var(--plus-minus-button-size);
    border-radius: var(--plus-minus-button-size);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    max-width: 100%; /* Ensures image does not exceed button width */
    max-height: 100%; /* Ensures image does not exceed button height */
}

</style>