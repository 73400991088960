import Vue from "vue";
import VueRouter from "vue-router";
import Onboarding from "../pages/Onboarding/Onboarding.vue";
import SubmitPlan from "../pages/Admin/SubmitPlan.vue"
import BeforeBuild from "../pages/Admin/BeforeBuild.vue"
import NotFound from "../pages/NotFound/components/NotFound.vue";
import Intake from "../pages/Intake/Intake.vue"

Vue.use(VueRouter);

const routes = [
  {
    path : '/',
    name : 'Onboarding',
    component: Onboarding,
    props: {inputMode: "main" }
  },
  {
    path : '/demo',
    name : 'Onboarding Demo',
    component: Onboarding,
    props: {inputMode: "demo"}
  },
  {
    path: '/view/:code(.*)',
    name: 'View Your Plans',
    component: Onboarding,
    props: (route) => ({
      planConfigCode: route.params.code,
      inputMode: "view"
    })
  },
  {
    path: '/build/:code(.*)',
    name: 'Build Your Plans',
    component: Onboarding,
    props: (route) => ({
      planConfigCode: route.params.code,
      inputMode: "build"
    })
  },
  {
    path: '/submit/:code(.*)',
    name: 'Submit Plan',
    component: SubmitPlan,
    props: (route) => ({
      planKey: route.params.code,
    })
  },
  {
    path: '/admin/:code(.*)',
    name: 'Plan Admin',
    component: BeforeBuild,
    props: (route) => ({
      planKey: route.params.code,
    })
  },
  {
    path: "/intake",
    name: "Intake Form",
    component: Intake,
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
